import { advertProductsType } from '../type';

const initialState = {
  allAdvertProducts: [],
  selectedAdvertProduct: {},
};

function advertProductsReducer(state = initialState, action) {
  switch (action.type) {
    case advertProductsType.GET_ALL_ADVERT_PRODUCTS:
      return {
        ...state,
        allAdvertProducts: action.allAdvertProducts,
      };
    case advertProductsType.GET_ADVERT_PRODUCT:
      return {
        ...state,
        selectedAdvertProduct: action.selectedAdvertProduct,
      };
    default:
      return state;
  }
}

export default advertProductsReducer;
