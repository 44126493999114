import jobsType from '../type/jobs';

const initialState = {
  jobs: [],
  statusList: [],
};

function jobs(state = initialState, action) {
  switch (action.type) {
    case jobsType.GET_JOBS:
      return {
        ...state,
        jobs: action.jobs,
      };
    case jobsType.GET_JOB_STATUS_LIST:
      return {
        ...state,
        statusList: action.data,
      };
    default:
      return state;
  }
}

export default jobs;
