import React, { useState, useEffect } from 'react';
import { Skeleton, Table, Tag } from 'antd';
import { accountHistoryListAPI } from '../../../../../services/billing_advertisers';

function AccountHistory(props) {
  const { client_id, history } = props;
  const [loadded, setLoaded] = useState(false);
  const [historyList, setHistoryList] = useState([]);

  useEffect(() => {
    if (client_id) {
      setLoaded(true);
      accountHistoryListAPI(client_id)
        .then((data) => {
          setLoaded(false);
          setHistoryList(data.data);
        })
        .catch((error) => {
          setLoaded(false);
        });
    }
  }, [client_id]);

  const columns = [
    {
      title: 'Date / Time',
      dataIndex: 'record_created',
      key: 'record_created',
    },
    {
      title: 'Product',
      key: 'advertisement_product_id',
      render: (text, record) => <span>{record?.advertisementProduct.advert_name}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status_id',
      key: 'status_id',
      render: (text, record) => <Tag color={record ? 'green' : 'volcano'}>{record ? 'SUCCESS' : 'Failure'}</Tag>,
    },
  ];

  return (
    <React.Fragment>
      <Skeleton active={loadded} loading={loadded}>
        <Table columns={columns} dataSource={historyList} />
      </Skeleton>
    </React.Fragment>
  );
}

export default AccountHistory;
