import React, { useState, useEffect } from 'react';

import { useLocation, NavLink } from 'react-router-dom';
import { Table } from 'antd';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';

import listHOC from '../../../common/listHOC';
import { getList } from '../../../../redux/actions/jobs';

const useStyles = createUseStyles((theme) => ({
  label: { fontWeight: theme.label_font_weight },
  inputDisabled: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
}));

const JobListPageComponent = ({ getColumnSearchProps, getJobListHandler, jobs, countries }) => {
  const location = useLocation();
  const theme = useTheme();
  const [activeTab, setState] = useState('');

  useEffect(() => {
    getJobListHandler();
  }, []);
  useEffect(() => {
    const { tab: activeTab } = queryString.parse(location.search);
    setState(activeTab);
  }, [location.search]);

  const data = jobs.map((job) => ({
    ...job,
    platinum_tick_job: job.platinum_tick_job ? 'Yes' : 'No',
  }));

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps('id', 'ID', (highlighter, record) => (
        <NavLink to={`/jobs/view/${record.id}?tab=job_summary`}>{highlighter}</NavLink>
      )),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      sorter: (a, b) => (a.createdAt ?? '').localeCompare(b.createdAt ?? ''),
      defaultSortOrder: 'descend',
      ...getColumnSearchProps('createdAt', 'Account ID'),
    },
    {
      title: 'Platinum Tick Job',
      dataIndex: 'platinum_tick_job',
      sorter: (a, b) => a.platinum_tick_job - b.platinum_tick_job,
      ...getColumnSearchProps('platinum_tick_job', 'Platinum Tick Job'),
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      sorter: (a, b) => (a.job_title ?? '').localeCompare(b.job_title ?? ''),
      ...getColumnSearchProps('job_title', 'Job Title'),
    },
    {
      title: 'Part Time',
      dataIndex: 'work_option_part_time',
      sorter: (a, b) => a.work_option_part_time - b.work_option_part_time,
      ...getColumnSearchProps('work_option_part_time', 'Part Time'),
    },
    {
      title: 'Full Time',
      dataIndex: 'work_option_full_time',
      sorter: (a, b) => a.work_option_full_time - b.work_option_full_time,
      ...getColumnSearchProps('work_option_full_time', 'Full Time'),
    },
    {
      title: 'Remote',
      dataIndex: 'remote_work',
      sorter: (a, b) => a.remote_work - b.remote_work,
      ...getColumnSearchProps('remote_work', 'Remote'),
    },
    {
      title: 'Location Region',
      dataIndex: 'location_region',
      sorter: (a, b) => (a.location_region ?? '').localeCompare(b.location_region ?? ''),
      ...getColumnSearchProps('location_region', 'Location Region'),
    },
    {
      title: 'Location Country',
      dataIndex: 'location_country_id',
      ...getColumnSearchProps('location_country_id', 'Location Country'),
      render: (text) => (
        <React.Fragment>{countries.find((item) => item.id == Number(`${text}`))?.full_name}</React.Fragment>
      ),
    },
    {
      title: 'Advertiser Reference',
      dataIndex: 'advertiser_reference',
      sorter: (a, b) => (a.advertiser_reference ?? '').localeCompare(b.advertiser_reference ?? ''),
      ...getColumnSearchProps('advertiser_reference', 'Advertiser reference'),
    },
  ];
  return (
    <React.Fragment>
      {/*
       * == Data field ==
       * id
       * created
       * job title
       * part time
       * full time
       * remote
       * location region
       * location country
       */}
      <Table columns={columns} dataSource={data} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    jobs: state.jobs.jobs,
    language: state.language,
    countries: state.countries.countries,
  };
};

const mapDispatchToProps = { getJobListHandler: getList };

export default connect(mapStateToProps, mapDispatchToProps)(listHOC(JobListPageComponent));
