import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

import {
  startNewAccountCreation,
  updateNewAccountCreation
} from "../../../../../redux/actions/accounts";

import NewAccountSinglePage from "./NewAccountSinglePage";

class AccountNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.props.startNewAccountCreation();
  }

  render() {
    const { classes } = this.props;
    const { current_step } = this.state;
    // console.log(current_step);
    console.log(this.props);
    // console.log(this.state.step_status_error);
    // if (!this.props.accounts && !this.props.accounts.new_account_data) {
    //   return null;
    // }

    return (
      <React.Fragment>
        <NewAccountSinglePage />
      </React.Fragment>
    );
  }
}

// export default withStyles(styles)(ClientNewPageComponent);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    accounts: state.accounts,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startNewAccountCreation: () => {
      dispatch(startNewAccountCreation())
    },
    updateNewAccountCreation: (key, value) => {
      dispatch(updateNewAccountCreation(key, value))
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const AccountNewPageBody = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountNewPageComponent));

export default AccountNewPageBody;
