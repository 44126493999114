import React, { useEffect, useState } from 'react';
import { Tabs, notification, Spin } from 'antd';
import { connect } from 'react-redux';

import { getJobAPI, updateJobAPI } from '../../../../services/jobs';

import JobDescription from './job_view/JobDescription';
import JobDetails from './job_view/JobDetails';
import JobAdvertisement from './job_view/JobAdvertisement';
import { useParams } from 'react-router-dom';

const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

const JobViewPageComponent = (props) => {
  const { countries, jobClassifications } = props;
  const [currentJob, setCurrentJob] = useState({});
  const [loading, setLoading] = useState(false);
  const [fieldStatus, setFieldStatus] = useState({
    job_title: '',
    short_description: '',
  });
  const { job_id } = useParams();
  useEffect(() => {
    setLoading(true);
    getJobAPI(job_id)
      .then((data) => {
        setCurrentJob(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const openNotificationWithIcon = (type, header, message) => {
    notification[type]({
      message: header,
      description: message,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    updateJobAPI(job_id, currentJob)
      .then((data) => {
        setLoading(false);
        if (data) {
          openNotificationWithIcon('success', 'Save Success', 'Job Info Updated.');
        } else {
          openNotificationWithIcon('error', 'Save Failed', 'An error occurred in Job Info Updating.');
        }
      })
      .catch((error) => {
        setLoading(false);
        openNotificationWithIcon('error', 'Save Failed', 'An error occurred in Job Info Updating.');
      });
  };

  return (
    <React.Fragment>
      <Spin spinning={loading} tip="Loading...">
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Job Description" key="description">
            <JobDescription
              currentJob={currentJob}
              setCurrentJob={setCurrentJob}
              countries={countries}
              setFieldStatus={setFieldStatus}
              fieldStatus={fieldStatus}
              jobClassifications={jobClassifications}
              handleSubmit={handleSubmit}
            />
          </TabPane>
          <TabPane tab="Details" key="details">
            <JobDetails currentJob={currentJob} setCurrentJob={setCurrentJob} handleSubmit={handleSubmit} />
          </TabPane>
          <TabPane tab="Advertisement" key="advertisement">
            <JobAdvertisement currentJob={currentJob} setCurrentJob={setCurrentJob} handleSubmit={handleSubmit} />
          </TabPane>
        </Tabs>
      </Spin>
    </React.Fragment>
  );
};

// export default JobViewPageComponent;

const mapStateToProps = (state) => {
  console.log(state);
  return {
    advertiser: state.advertiser,
    user: state.auth.currentUser,
    countries: state.countries.countries,
    jobClassifications: state.jobClassifications,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(JobViewPageComponent);
