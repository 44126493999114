import { accountType } from '../type';
import { getListAPI, getAccountAPI } from '../../services/accounts';

export const ACCOUNTS_START_NEW_ACCOUNT_CREATION = 'ACCOUNTS_START_NEW_ACCOUNT_CREATION';
export const ACCOUNTS_UPDATE_NEW_ACCOUNT_CREATION = 'ACCOUNTS_UPDATE_NEW_ACCOUNT_CREATION';

export function startNewAccountCreation() {
  return { type: ACCOUNTS_START_NEW_ACCOUNT_CREATION };
}

export function updateNewAccountCreation(key, value) {
  return {
    type: ACCOUNTS_UPDATE_NEW_ACCOUNT_CREATION,
    value: {
      key,
      value,
    },
  };
}

const getAccounts = () => {
  return (dispatch) => {
    return getListAPI()
      .then((data) => {
        if (data) {
          return dispatch({
            type: accountType.GET_ACCOUNTS,
            accounts: data.data,
          });
        }
      })
      .catch((error) => {
        // throw new Error(error);
      });
  };
};

const getAccount = (id) => {
  return getAccountAPI(id)
    .then((data) => {
      if (data) {
        return data.data;
      }
      throw new Error(data.msg);
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export { getAccounts, getAccount };
