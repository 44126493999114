import { countryType } from '../type';

const initialState = { countries: [] };

function countries(state = initialState, action) {
  switch (action.type) {
    case countryType.GET_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
      };
    default:
      return state;
  }
}

export default countries;
