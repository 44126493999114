
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, PageHeader, Button } from 'antd';

import ApplicantListPageBody from './ApplicantListPageBody';
import { getList } from '../../../../redux/actions/applicants';

function ApplicantListPage (props) {
  const {
    getListHandler, history, applicantsList
  } = props;

  useEffect(() => {
    getListHandler()
      .then(data => {
        console.log('getApplicantList Result', data);
      })
      .catch(error => {
        console.log('getApplicantList-Error', error);
      })
  }, [])

  const handle_show_all = () => {
    history.push('/applicants/list/');
  }

  const handle_new_applicants = () => {
    history.push('/applicants/new');
  }

  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title="Applicant Management"
      />
      <Card
        style={{ minHeight: 360 }}
        title="Current Applicant List"
        extra={[
          <Button key="3" onClick={handle_new_applicants}>Create New</Button>,
          <Button key="2" disabled>View Record</Button>,
          <Button key="1" onClick={handle_show_all} type="primary">Show All</Button>,
        ]}
      >
        <div style={{ paddingTop: 24 }}>
          <ApplicantListPageBody applicantsList = {applicantsList} />
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    applicantsList: state.applicants.applicants, // Applicants Action Store
  };
};
const mapDispatchToProps = ({
  getListHandler: getList, // Applicants Action function
})

export default connect(mapStateToProps, mapDispatchToProps) (ApplicantListPage);
