import React from 'react';
import { connect } from 'react-redux';
import { setLanguageSelectId } from '../../redux/actions/language';
import { PageHeader, Button, Select } from 'antd';

import ReactCountryFlag from 'react-country-flag';

const { Option } = Select;
function PagesHeader(props) {
  const {
    setLanguageSelectId, handleLogout, language, isAuthenticated
  } = props;
  const handleLanguageSelect = (value) => {
    setLanguageSelectId(value);
  };

  const logout = async (event) => {
    handleLogout();
  };

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title="Admin Portal"
        extra={
          isAuthenticated
            ? [
              <Select
                defaultValue={language.language_current_ui}
                style={{ width: 180 }}
                onChange={handleLanguageSelect}
              >
                <Option value="en">
                  <ReactCountryFlag
                    className="emojiFlag"
                    countryCode="GB"
                    style={{
                      fontSize: '1.5em',
                      lineHeight: '1.5em',
                    }}
                    aria-label="English Language"
                  />{' '}
                    English
                </Option>
                <Option value="zh">
                  <ReactCountryFlag
                    countryCode="CN"
                    style={{
                      fontSize: '1.5em',
                      lineHeight: '1.5em',
                    }}
                    aria-label="Chinese Traditional Language"
                  />{' '}
                    繁體中文
                </Option>
              </Select>,
              <Button key="1" type="primary" onClick={logout}>
                  Log Out
              </Button>,
            ]
            : []
        }
      ></PageHeader>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguageSelectId: (language_id) => {
      dispatch(setLanguageSelectId(language_id));
    },
  };
};

const PagesHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(PagesHeader);

export default PagesHeaderContainer;
