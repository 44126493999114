import React, { Component } from 'react';
import { PageHeader, Button, Card } from 'antd';
import AccountViewPageBody from './body';

class AccountViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handle_show_all = () => {
    this.props.history.push('/account/list/');
  }

  handle_new_accounts = () => {
    this.props.history.push('/account/new');
  }

  render() {

    console.log(this.props.match.params.account_id)

    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Account Management"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View your Account Details"
          // extra={[
          //   <Button key="3" disabled onClick={this.handle_new_accounts}>Create New</Button>,
          //   <Button key="2" disabled>View Record</Button>,
          //   <Button key="1" disabled onClick={this.handle_show_all} type="primary">
          //           Show All
          //   </Button>,
          // ]}
        >
          <div style={{ paddingTop: 24 }}>
          <AccountViewPageBody
            account_id={this.props.match.params.account_id}
           />

          </div>
        </Card>
      </div>

    );
  }
}

export default AccountViewPage;
