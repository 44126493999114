import React from 'react';
import { PageHeader, Button, Card } from 'antd';
import AccountListPageBody from './AccountListPageBody';

function AccountListPage(props) {
  const { history } = props;
  const handleShowAll = () => {
    history.push('/account/list/');
  };

  const handleNewAccounts = () => {
    history.push('/account/new');
  };

  return (
    <div
      style={{
        padding: 24,
        background: '#fff',
        minHeight: 360,
      }}
    >
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16,
        }}
        title="Account Management"
      />
      <Card
        style={{ minHeight: 360 }}
        title="Current Account List"
        extra={[
          <Button key="3" onClick={handleNewAccounts}>
            Create New
          </Button>,
          <Button key="2" disabled>
            View Record
          </Button>,
          <Button key="1" onClick={handleShowAll} type="primary">
            Show All
          </Button>,
        ]}
      >
        <div style={{ paddingTop: 24 }}>
          <AccountListPageBody />
        </div>
      </Card>
    </div>
  );
}

export default AccountListPage;
