
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Select, Form, Input } from 'antd';

function ClientDetails(props) {
  const { accounts, setNewAdvertiser, newAdvertiser } = props;
  const [options, setOtions] = useState([]);
  useEffect(() => {
    const { Option } = Select;
    const result = accounts.accounts.map((d, index) => <Option key={`accounts-${index}`} value={d.id} title={d.file_by}>{d.file_by}</Option>);
    setOtions(result);
  }, []);

  const handleChange = (event) => {

    const valueTarget = event.target;
    setNewAdvertiser(prev => ({
      ...prev,
      [valueTarget.id]: valueTarget.value
    }));
    // Update Parent
  };

  const handleChangeOption = (value) => {
    setNewAdvertiser(prev => ({
      ...prev,
      account_id: value
    }));
  };

  return (

    <React.Fragment>
      <Form layout="vertical">
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Row gutter={[12, 0]}>
              <Col xs={12}>
                <Form.Item
                  label="First Name"
                  style={{ marginBottom: 0 }}
                  hasFeedback
                >
                  <Input
                    id="first_name"
                    value={newAdvertiser.first_name}
                    onChange={event => {
                      handleChange(event);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label="Middle Name(s)" style={{ marginBottom: 0 }}>
                  <Input
                    id="middle_name"
                    value={newAdvertiser.middle_name}
                    onChange={event => {
                      handleChange(event);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Last Name"
              hasFeedback
            >
              <Input
                id="last_name"
                value={newAdvertiser.last_name}
                onChange={event => {
                  handleChange(event);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Account"
              style={{ marginBottom: 0 }}
              hasFeedback
            //help={this.props.clients.new_client_data.account_id_status === "error" ? "Account User ID is required" : ""}
            >
              <Select
                id="account_id"
                showSearch
                defaultValue={newAdvertiser.account_id}
                placeholder="Select Account"
                showArrow={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                onChange={handleChangeOption}
              >
                {options}

              </Select>
            </Form.Item>
            <Form.Item
              label="E-mail"
              hasFeedback
            >
              <Input
                id="email"
                value={newAdvertiser.email}
                onChange={event => {
                  handleChange(event);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    accounts: state.accounts
  };
};

const mapDispatchToProps = ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientDetails);