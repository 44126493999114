import React, {
  useState,
  useEffect
} from 'react';

import {
  NavLink
} from 'react-router-dom';
import { Table, Spin } from 'antd';
import { connect } from 'react-redux';

import listHOC from '../../../../../components/common/listHOC';
import { getAllBillingApp } from '../../../../../redux/actions/billing_applicants';
import { getList } from '../../../../../redux/actions/applicants';

const BillingApplicantListPageComponent = (props) => {

  const { getAllBillingAppHandler, allBillingApp, getApplicantsHandler } = props;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadingAPIs();
  }, []);
  const columns = [
    {
      title: 'Subscription ID',
      dataIndex: 'id',
      render: (highlighter, record) => <NavLink to={`/billing/applicants/view/${record.id}`}>{record.id}</NavLink>
    },
    {
      title: 'Applicant ID',
      dataIndex: 'applicant.id',
      render: (highlighter, record) => <NavLink to={`/applicants/view/${record.applicant.id}`}>{record.applicant.id}</NavLink>
    },
    {
      title: 'First Name',
      dataIndex: 'applicant.first_name',
      render: (highlighter, record) => record.applicant.first_name,
    },
    {
      title: 'Last Name',
      dataIndex: 'applicant.last_name',
      render: (highlighter, record) => record.applicant.last_name,
    },
    { 
      title: 'Email',
      dataIndex: 'applicant.email',
      render: (highlighter, record) => record.applicant.email,
    },
    {
      title: 'Subscription Status',
      dataIndex: 'admin_subscription_status.file_by',
      render: (highlighter, record) => record.admin_subscription_status.file_by,
    },
  ];

  const loadingAPIs = async() => {
    setLoading(true);
    await getAllBillingAppHandler();
    await getApplicantsHandler();
    setLoading(false);
  };

  return <React.Fragment>
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={allBillingApp}
      />
    </Spin>
  </React.Fragment>;
};

const mapStateToProps = state => {
  return {
    allBillingApp: state.billingApp.allBillingApp
  };
};


const mapDispatchToProps = ({
  getAllBillingAppHandler: getAllBillingApp,
  getApplicantsHandler: getList
});

export default connect(mapStateToProps, mapDispatchToProps)(listHOC(BillingApplicantListPageComponent));
