import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Select, Typography } from 'antd';
import CountrySeleteComponent from '../../../../../components/common/CountrySeleteComponent';
const { Title } = Typography;
const { Option } = Select;

function ContactDetails(props) {
  const { setNewApplicant, newApplicant, countries } = props;

  const handleChange = (event) => {
    const target = event.target;
    setNewApplicant(prev => ({
      ...prev,
      [target.id]: target.value
    }));
  };

  const handleChangeStreetCountry = (value) => {
    setNewApplicant(prev => ({
      ...prev,
      'street_address_country': value
    }));
  };

  const handleChangePostalCountry = (value) => {
    setNewApplicant(prev => ({
      ...prev,
      'postal_address_country': value
    }));
  };

  const options = countries.countries.map((d, index) => <Option key={`countries-${index}`} value={d.id} >{d.full_name}</Option>);

  return (
    <React.Fragment>
      <Form layout="vertical">
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Title level={4}>Contact Details</Title>
            <Form.Item label="Telephone (home)" style={{ marginBottom: 0 }}>
              <Input
                id="tel_home"
                value={newApplicant.tel_home}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Telephone (work)" style={{ marginBottom: 0 }}>
              <Input
                id="tel_work"
                value={newApplicant.tel_work}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Telephone (mobile)" style={{ marginBottom: 0 }}>
              <Input
                id="tel_mobile"
                value={newApplicant.tel_mobile}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Primary Email" style={{ marginBottom: 0 }}>
              <Input
                id="email"
                value={newApplicant.email}
                disabled
              />
            </Form.Item>
            <Form.Item label="Secondary Email" style={{ marginBottom: 0 }}>
              <Input
                id="email_secondary"
                value={newApplicant.email_secondary}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Title level={4}>Street Address</Title>
            <Form.Item
              label="Street Address (Line 1)"
              style={{ marginBottom: 0 }}
            >
              <Input
                id="street_address_line_1"
                value={newApplicant.street_address_line_1}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item
              label="Street Address (Line 2)"
              style={{ marginBottom: 0 }}
            >
              <Input
                id="street_address_line_2"
                value={newApplicant.street_address_line_2}
                onChange={handleChange}
              />
            </Form.Item>
            <Row gutter={[12, 0]}>
              <Col xs={12}>
                <Form.Item label="Suburb"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    id="street_address_suburb"
                    value={newApplicant.street_address_suburb}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  label="State"
                  style={{ marginBottom: 0 }}>
                  <Input
                    id="street_address_state"
                    value={newApplicant.street_address_state}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={4}>
                <Form.Item label="PostCode"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    id="street_address_postcode"
                    value={newApplicant.street_address_postcode}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Country">
              <CountrySeleteComponent
                id="street_address_country"
                value={newApplicant.street_address_country}
                handleChange={handleChangeStreetCountry} />
            </Form.Item>
            <br />
            <Title level={4}>Postal Address</Title>
            <Form.Item
              label="Street Address (Line 1)"
              style={{ marginBottom: 0 }}
            >
              <Input
                id="postal_address_line_1"
                value={newApplicant.postal_address_line_1}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item
              label="Street Address (Line 2)"
              style={{ marginBottom: 0 }}
            >
              <Input
                id="postal_address_line_2"
                value={newApplicant.postal_address_line_2}
                onChange={handleChange}
              />
            </Form.Item>
            <Row gutter={[12, 0]}>
              <Col xs={12}>
                <Form.Item label="Suburb"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    id="postal_address_suburb"
                    value={newApplicant.postal_address_suburb}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  label="State"
                  style={{ marginBottom: 0 }}>
                  <Input
                    id="postal_address_state"
                    value={newApplicant.postal_address_state}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={4}>
                <Form.Item label="PostCode"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    id="postal_address_postcode"
                    value={newApplicant.postal_address_postcode}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Country">
              <CountrySeleteComponent
                id="postal_address_country"
                value={newApplicant.postal_address_country}
                handleChange={handleChangePostalCountry} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    countries: state.countries
  };
};

const mapDispatchToProps = ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactDetails);
