import accountType from '../type/accounts';

const initialState = { accounts: [] };

function accounts(state = initialState, action) {
  switch (action.type) {
    case accountType.GET_ACCOUNTS:
      return {
        ...state,
        accounts: action.accounts,
      };
    default:
      return state;
  }
}

export default accounts;
