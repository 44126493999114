import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppContainer from './App';

import * as serviceWorker from './serviceWorker';

import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './redux/reducers/index.js';

import { createLogger } from 'redux-logger';

import thunkMiddleware from 'redux-thunk';

const logger = createLogger({ collapsed: true });

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, logger));

ReactDOM.render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
