import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Select, Spin, Button, notification, Form, Input, Typography } from 'antd';
import { updateAplicant } from '../../../../../redux/actions/applicants';
import { createCustomerAppAPI } from '../../../../../services/billing_applicants';
const { Text } = Typography;

const { Option } = Select;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

function ApplicantBilling(props) {
  const { client_id, updateAplicantHandler, applicants } = props;
  const [loadded, setLoaded] = useState(false);
  const [billingInfo, setBillingInfo] = useState({
    stripe_id: ''
  });
  useEffect(() => {
    if (applicants.length > 0) {
      const applicantInfo = applicants.find(item => item.id == client_id);
      setBillingInfo( (prev) => ({
        ...prev,
        stripe_id: applicantInfo.stripe_id ? applicantInfo.stripe_id: ''
      }));
    }
  }, [applicants]);

  const handleChange = event => {
    const { id, value } = event.target;
    setBillingInfo(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    setLoaded(true);
    updateAplicantHandler(client_id, billingInfo)
      .then(data => {
        setBillingInfo((prev) => ({
          ...prev,
          stripe_id: data.stripe_id,
        }));
        setLoaded(false);
      }).catch(erorr => {
        setLoaded(false);
      })
  };

  const handleCreateStripID = async () => {
    try {
      setLoaded(true);
      const updateApplicant = await createCustomerAppAPI(client_id);
      openNotificationWithIcon('success', 'Stripe', 'Success creating stripe ID!');
      setBillingInfo((prev) => ({
        ...prev,
        stripe_id: updateApplicant.data.stripe_id,
      }));
      setLoaded(false);
    } catch (error) {
      openNotificationWithIcon('error', 'Stripe', 'Unable to create stripe ID!');
      setLoaded(false);
    }
  };

  return (
    <React.Fragment>
      <Spin
        spinning={loadded}
      >
        <Form
          layout="vertical"
          onSubmit={handleSubmit}
        >
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Text>{`Stripe ID: ${billingInfo.stripe_id}`}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: 'right' }}>
              <Button
                style={{ marginRight: 20 }}
                onClick={handleCreateStripID}
                disabled={billingInfo.stripe_id}
              >
                Create Stripe ID
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    applicants: state.applicants.applicants,
  };
};

const mapDispatchToProps = ({
  updateAplicantHandler: updateAplicant,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantBilling);