import {
  getJobProfilesAPI,
  updateJobProfilesAPI,
  getJobProfilesListAPI,
  createJobProfilesAPI,
} from '../../services/jobProfiles';
import { jobProfilesType } from '../type';

const getJobProfiles = (id) => {
  return (dispatch) => {
    return getJobProfilesAPI(id)
      .then((data) => {
        return dispatch({
          type: jobProfilesType.GET_JOB_PROFILES,
          jobProfiles: data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const updateJobProfiles = (id, params) => {
  return (dispatch) => {
    return updateJobProfilesAPI(id, params)
      .then((data) => {
        return dispatch({
          type: jobProfilesType.UPDATE_JOB_PROFILES,
          jobProfiles: data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const getJobProfilesList = () => {
  return (dispatch) => {
    return getJobProfilesListAPI()
      .then((data) => {
        return dispatch({
          type: jobProfilesType.GET_JOB_PROFILES_LIST,
          jobProfilesList: data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const createJobProfiles = (id, params) => {
  return (dispatch) => {
    return createJobProfilesAPI(id, params)
      .then((data) => {
        return dispatch({
          type: jobProfilesType.CREATE_JOB_PROFILES,
          jobProfiles: data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export { getJobProfiles, updateJobProfiles, getJobProfilesList, createJobProfiles };
