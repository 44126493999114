import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Space, Typography, Spin, PageHeader, Card} from 'antd';
import {useParams} from 'react-router-dom';
import {getSubscriptionAPI} from '../../../../services/subscripitions';

const {Text, Title} = Typography;

const SubscriptionView = props => {
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});

  useEffect(() => {
    if (id) {
      setLoading(true);
      getSubscriptionAPI(id)
        .then(data => {
          setSubscriptionData(data.data);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <div
      style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}
    >
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Subscription'
      />
      <Card style={{minHeight: 360}}>
        <React.Fragment>
          <Spin spinning={loading}>
            {subscriptionData && (
              <Space direction='vertical' size={10}>
                <Space direction='horizontal'>
                  <Text style={{fontWeight: 'bold'}}>ID:</Text>
                  <Text>{subscriptionData?.id}</Text>
                </Space>
                <Space direction='horizontal'>
                  <Text style={{fontWeight: 'bold'}}>Month Count:</Text>
                  <Text>{subscriptionData?.month_count}</Text>
                </Space>
                <Space direction='horizontal'>
                  <Text style={{fontWeight: 'bold'}}>Price:</Text>
                  <Text>{subscriptionData?.price}</Text>
                </Space>
                <Space direction='horizontal'>
                  <Text style={{fontWeight: 'bold'}}>Stripe ID:</Text>
                  <Text>{subscriptionData?.stripe_id}</Text>
                </Space>
                <Space direction='horizontal'>
                  <Text style={{fontWeight: 'bold'}}>File_By:</Text>
                  <Text>{subscriptionData?.subscription_products?.file_by}</Text>
                </Space>
                <Space direction='horizontal'>
                  <Text style={{fontWeight: 'bold'}}>Stripe ID:</Text>
                  <Text>{subscriptionData?.subscription_products?.stripe_id}</Text>
                </Space>
              </Space>
            )}
          </Spin>
        </React.Fragment>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    jobProfileStore: state.jobProfiles.jobProfiles
  };
};

export default connect(mapStateToProps, null)(SubscriptionView);
