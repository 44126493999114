import { ADMINS_START_NEW_STAFF_CREATION, ADMINS_UPDATE_NEW_STAFF_CREATION } from '../actions/admins';

const initialState = {
  new_admin_data: null,
  new_admin_submitted: false,
  new_admin_created: false,
};

function admins(state = initialState, action) {
  switch (action.type) {
    case ADMINS_START_NEW_STAFF_CREATION:
      console.log('=debug=');
      return {
        ...state, // staff_detail: action.staff_detail,
        new_admin_data: {
          first_name: '',
          last_name: '',
          email: '',
        },
        new_admin_submitted: false,
        new_admin_created: false,
        // client_detail_loaded: true
        // staff_detail_loaded: true
      };
    case ADMINS_UPDATE_NEW_STAFF_CREATION:
      return {
        ...state,
        new_admin_data: {
          ...state.new_admin_data,
          [action.value.key]: action.value.value,
        },
      };
    default:
      return state;
  }
}

// const rootReducer = combineReducers({
//   app_state
// });

export default admins;
