
import React, { Component } from 'react';
import { Card, PageHeader, Button } from 'antd';
import AdvertiserViewPageBody from './body';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

function AdvertiserViewPage (props) {

  const handle_show_all = () => {
    props.history.push('/advertisers/list/');
  }

  const handle_new_advertisers = () => {
    props.history.push('/advertisers/new');
  }

    let params = queryString.parse(useLocation().search)
    console.log(params);

    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Advertiser Management"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View and update your Advertiser Details"
          extra={[
            <Button key="3" onClick={handle_new_advertisers}>Create New</Button>,
            <Button key="2" disabled>View Record</Button>,
            <Button key="1" onClick={handle_show_all} type="primary">
                    Show All
            </Button>,
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <AdvertiserViewPageBody
              client_id={props.match.params.client_id}
              tab={params?.tab}
            />

          </div>
        </Card>
      </div>

    );
  }

export default AdvertiserViewPage;
