import advertisersType from '../type/advertisers';

const initialState = { advertisers: [] };

function advertisers(state = initialState, action) {
  switch (action.type) {
    case advertisersType.GET_ADERTISERS:
      return {
        ...state,
        advertisers: action.advertisers,
      };
    default:
      return state;
  }
}

export default advertisers;
