import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API } from 'aws-amplify';
import { Row, Col, Button, notification, Form, Input } from 'antd';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class AccountSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const account = await this.getAccountRecord(this.props.account_id);
    console.log(account);
    this.setState({ ...account.payload });
  }

  async getAccountRecord(id) {
    return API.get('accounts', `/get-by-id/${id}`);
  }

  async saveAccountRecord(account_record) {
    await API.put('accounts', `/update/${this.state.id}`, { body: account_record });
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully');
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log(event);
    this.setState({ isLoading: true });

    const { file_by, admin_user_id, account_slug, account_name } = this.state;
    try {
      await this.saveAccountRecord({
        file_by,
        admin_user_id,
        account_slug,
        account_name,
      });
    } catch (e) {
      alert(e);
      try {
        await this.saveAccountRecord({
          file_by,
          admin_user_id,
          account_slug,
          account_name,
        });
      } catch (e) {
        openNotificationWithIcon('error', 'Save Failed', 'An error occurred in data saving');
        this.setState({ isLoading: false });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="File by">
                    <Input
                      id="file_by"
                      value={this.state.file_by}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Account Slug">
                    <Input
                      id="account_slug"
                      value={this.state.account_slug}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Account Admin">
                    <Input
                      id="admin_user_id"
                      disabled
                      //value={this.props.app_state.current_user_id}
                      value={this.state.staff_file_by} //this.state.admin_user_id
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Account Name">
                    <Input
                      id="account_name"
                      value={this.state.account_name}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[96, 24]}>
            <Col xs={24} style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  };
};

const AccountSummaryContainer = connect(mapStateToProps, mapDispatchToProps)(AccountSummary);

export default AccountSummaryContainer;
