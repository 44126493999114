export default {
  API_URL: {
    DEV: {
      BASE_URL: 'BASE_URL',
      API_BASE_URL: 'http://localhost:2017',
    },
    STAGING: {
      BASE_URL: 'BASE_URL',
      API_BASE_URL: 'https://api01.platinumjobseeker.com',
    },
  },
};
