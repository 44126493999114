/* Definded the action type for advertisers */
import billingAdvType from '../type/billing_advertisers';

const initialState = {
  /* Advertisers List Store*/
  allBillingAdv: [],
  selectedBillingAdv: {},
};

function billingAdvReducer(state = initialState, action) {
  switch (action.type) {
    /* Advertiser Reducer to save the advertisers list */
    case billingAdvType.GET_ALL_ADV_SUB:
      return {
        ...state,
        allBillingAdv: action.allBillingAdv,
      };
    case billingAdvType.GET_ADV_SUB:
      return {
        ...state,
        selectedBillingAdv: action.selectedBillingAdv,
      };
    default:
      return state;
  }
}

export default billingAdvReducer;
