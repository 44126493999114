import React, { useState, useEffect } from 'react';
import { Skeleton, Table } from 'antd';
import { productBalanceListAPI } from '../../../../../services/billing_advertisers';

function ProductBalances(props) {
  const { client_id, history } = props;
  const [loadded, setLoaded] = useState(false);
  const [balanceList, setBalanceList] = useState([]);

  useEffect(() => {
    if (client_id) {
      setLoaded(true);
      productBalanceListAPI(client_id)
        .then((data) => {
          setLoaded(false);
          setBalanceList(data.data);
        })
        .catch((error) => {
          setLoaded(false);
        });
    }
  }, [client_id]);

  const columns = [
    {
      title: 'Advertisement Product Id',
      key: 'advertisement_product_id',
      render: (text, record) => <span>{record?.advertisementProduct.advert_name}</span>,
    },
    {
      title: 'Current Balance',
      dataIndex: 'current_balance',
      key: 'current_balance',
    },
  ];

  return (
    <React.Fragment>
      <Skeleton active={loadded} loading={loadded}>
        <Table columns={columns} dataSource={balanceList} />
      </Skeleton>
    </React.Fragment>
  );
}

export default ProductBalances;
