import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const getJobProfilesAPI = (id) => {
  const path = `/admin/v1/jobProfiles/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const updateJobProfilesAPI = (id, params) => {
  const path = `/admin/v1/jobProfiles/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const getJobProfilesListAPI = () => {
  const path = '/admin/v1/jobProfiles';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const createJobProfilesAPI = (id, params) => {
  const path = `/admin/v1/jobProfiles/create/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

export { getJobProfilesAPI, updateJobProfilesAPI, getJobProfilesListAPI, createJobProfilesAPI };
