/* Definded the action type for WorkProfileAPI */
import workProfileType from '../type/workProfile';

const initialState = {
  /* workProfile List Store*/
  workProfile: [],
};

function workProfile(state = initialState, action) {
  switch (action.type) {
    /* WorkProfile Reducer to save the WorkProfileAPI list */
    case workProfileType.GET_WORKPROFILE:
      return {
        ...state,
        workProfile: action.workProfile,
      };
    default:
      return state;
  }
}

export default workProfile;
