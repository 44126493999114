import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Form, DatePicker, Input, Select, Button, Radio} from 'antd';
import moment from 'moment';
import {getStatusList} from '../../../../../redux/actions/jobs';

const {Option} = Select;

const dateFormat = 'YYYY/MM/DD';

const JobDescription = ({
  fieldStatus,
  currentJob,
  setCurrentJob,
  countries,
  jobClassifications,
  handleSubmit,
  getStatusListHandler,
  statusList
}) => {
  useEffect(() => {
    getStatusListHandler();
  }, []);

  const handleChange = event => {
    const {id, value} = event.target;
    setCurrentJob(prev => ({
      ...prev,
      [id]: value
    }));
  };
  const handleJobStartChange = (date, dateString) => {
    if (date && dateString)
      setCurrentJob(prev => ({
        ...prev,
        job_start: dateString
      }));
  };

  const handleChangeCountryId = value => {
    setCurrentJob(prev => ({
      ...prev,
      country_id: value
    }));
  };

  const handleChangeJobClassificationId = value => {
    setCurrentJob(prev => ({
      ...prev,
      job_classification_id: value
    }));
  };

  const handleJobExpireChange = (date, dateString) => {
    if (date && dateString)
      setCurrentJob(prev => ({
        ...prev,
        job_expire: dateString
      }));
  };

  const options = countries?.map((d, index) => (
    <Option key={`countries-${index}`} value={d.id}>
      {d.full_name}
    </Option>
  ));
  const jobClassification = jobClassifications?.map((d, index) => (
    <Option key={`jobClassifications-${index}`} value={d.id}>
      {d.file_by}
    </Option>
  ));
  const jobStatusOptions = statusList.map(status => ({label: status.file_by, value: status.id}));
  console.log('statusList', statusList, jobStatusOptions);

  return (
    <React.Fragment>
      <Form layout='vertical'>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Form.Item
              label='Job Title'
              style={{marginBottom: 0}}
              validateStatus={fieldStatus.job_title}
              hasFeedback
              help={fieldStatus.job_title === 'error' ? 'Job Title is required' : ''}
            >
              <Input id='job_title' value={currentJob.job_title} onChange={event => handleChange(event)} />
            </Form.Item>
            <Form.Item
              label='Short Description'
              validateStatus={fieldStatus.short_description}
              hasFeedback
              help={fieldStatus.short_description === 'error' ? 'Short Description is required' : ''}
            >
              <Input
                id='short_description'
                value={currentJob.short_description}
                onChange={event => handleChange(event)}
              />
            </Form.Item>
            <Form.Item label='Job Category'>
              <Select
                id='job_classification_id'
                showSearch
                value={currentJob.job_classification_id}
                placeholder='Select Country'
                showArrow={true}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={handleChangeJobClassificationId}
              >
                {jobClassification}
              </Select>
              {fieldStatus.job_classification_id === 'error' ? (
                <div class='ant-form-item-explain ant-form-item-explain-error'>Job Category is required</div>
              ) : (
                ''
              )}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label='Job Status'>
              <Radio.Group
                options={jobStatusOptions}
                id='status_id'
                onChange={event => handleChange({target: {value: event.target.value, id: 'status_id'}})}
                value={currentJob.status_id}
                optionType='button'
                buttonStyle='solid'
              />
            </Form.Item>
            <Form.Item label='Location Region'>
              <Input id='location_region' value={currentJob.location_region} onChange={event => handleChange(event)} />
            </Form.Item>
            <Form.Item label='Location Country'>
              <Select
                id='location_country_id'
                showSearch
                value={currentJob.location_country_id}
                placeholder='Select Country'
                showArrow={true}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={handleChangeCountryId}
              >
                {options}
              </Select>
            </Form.Item>
            <Row>
              <Form.Item label='Job start'>
                <DatePicker
                  value={moment(currentJob.job_start, dateFormat)}
                  format={dateFormat}
                  onChange={handleJobStartChange}
                />
              </Form.Item>
              <Form.Item label='Job expire' style={{marginLeft: 30}}>
                <DatePicker
                  value={moment(currentJob.job_expire, dateFormat)}
                  format={dateFormat}
                  onChange={handleJobExpireChange}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col xs={24} style={{textAlign: 'right'}}>
            <Button type='primary' htmlType='submit' onClick={handleSubmit}>
              Save Record
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    statusList: state.jobs.statusList
  };
};

const mapDispatchToProps = {
  getStatusListHandler: getStatusList
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDescription);
