import Amplify, { Auth } from 'aws-amplify';
import flatten from 'flat';
import createHistory from 'history/createBrowserHistory';
import { getJobClassifications } from './redux/actions/job_classifications';

import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'react-jss';
import { connect } from 'react-redux';

import { Row, Spin } from 'antd';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import AuthenticatedRoute from './components/routes/AuthenticatedRoute';
import UnauthenticatedRoute from './components/routes/UnauthenticatedRoute';
import AppliedRoute from './components/routes/AppliedRoute';

import config from './config/aws_config';
import { signOut, validateToken } from './redux/actions/auth';
import { getAccounts } from './redux/actions/accounts';
import { getAdvertisers } from './redux/actions/advertisers';
import { getCountries } from './redux/actions/countries';
import { getList } from './redux/actions/workProfile';
import { authRoutes, unAuthRoutes, appliedRoutes } from './routes/index.jsx';
import messages_en from './translations/en.json';
import messages_zh from './translations/zh.json';
const history = createHistory();
ReactGA.initialize('UA-179934135-3');
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito_admins.REGION,
    userPoolId: config.cognito_admins.USER_POOL_ID,
    identityPoolId: config.cognito_admins.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito_admins.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito_admins.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: 'accounts',
        endpoint: 'https://c2vydngk25.execute-api.us-east-1.amazonaws.com/dev/accounts',
        region: config.apiGateway.REGION,
      },
      {
        name: 'admins',
        endpoint: 'https://mk07bxov81.execute-api.us-east-1.amazonaws.com/dev/admin',
        region: config.apiGateway.REGION,
      },
      {
        name: 'applicants',
        endpoint: 'https://kvvvz7hv0b.execute-api.us-east-1.amazonaws.com/dev/applicants',
        region: config.apiGateway.REGION,
      },
      {
        name: 'advertisers',
        endpoint: 'https://sqqkwydk01.execute-api.us-east-1.amazonaws.com/dev/advertisers',
        region: config.apiGateway.REGION,
      },
      {
        name: 'countries',
        endpoint: 'https://hgxtkxbzm4.execute-api.us-east-1.amazonaws.com/dev/countries',
        region: config.apiGateway.REGION,
      },
      {
        name: 'email',
        endpoint: ' https://f6r7zyzych.execute-api.us-east-1.amazonaws.com/dev/email',
        region: config.apiGateway.REGION,
      },
      {
        name: 'user-advertisers',
        endpoint: 'https://XXXXXX.execute-api.us-east-1.amazonaws.com/dev/user-client',
        region: config.apiGateway.REGION,
      },
      {
        name: 'user-admins',
        endpoint: 'https://7xhw2jdr90.execute-api.us-east-1.amazonaws.com/dev/user-admins',
        region: config.apiGateway.REGION,
      },
      {
        name: 'user-applicants',
        endpoint: 'https://XXXXXX.execute-api.us-east-1.amazonaws.com/dev/user-client',
        region: config.apiGateway.REGION,
      },
    ],
  },
});

const messages = {
  en: messages_en,
  zh: messages_zh,
};

const theme = {
  label_font_weight: 500,
  input_disabled_color: '#333 !important',
  input_disbaled_background_color: '#fafafa !important',
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      initialStateSample: '',
      current_user: null,
      loading: true,
    };
  }

  async componentDidMount() {
    await this.props.validateTokenHandler();
    await this.props.getAccountsHandler();
    await this.props.getCountriesHandler();
    await this.props.getJobClassificationsHandler();
    await this.props.getAdvertisersHandler();
    await this.props.getWorkplaceProfileListHandler();
    this.setState({ loading: false });
  }

  handleLogout = () => {
    this.props.signOutHandler();
  };

  render() {
    //hardcode as true for template, change it when project starts
    const childProps = {
      isAuthenticated: this.props.auth.isAuthenticated,
      handleLogout: this.handleLogout,
    };

    return (
      <IntlProvider
        locale={this.props.language.language_current_ui}
        messages={flatten(messages[this.props.language.language_current_ui])}
      >
        <ThemeProvider theme={theme}>
          <Router history={history}>
            {!this.state.loading ? (
              <Switch>
                {appliedRoutes.map((prop, key) => {
                  return <AppliedRoute path={prop.path} component={prop.component} key={`${key}`} props={childProps} />;
                })}
                {unAuthRoutes.map((prop, key) => {
                  return (
                    <UnauthenticatedRoute
                      path={prop.path}
                      component={prop.component}
                      key={`unauth_${key}`}
                      props={childProps}
                    />
                  );
                })}
                {authRoutes.map((prop, key) => {
                  return (
                    <AuthenticatedRoute
                      path={prop.path}
                      component={prop.component}
                      key={`auth_${key}`}
                      props={childProps}
                    />
                  );
                })}
              </Switch>
            ) : (
              <Row
                style={{
                  justifyContent: 'center',
                  height: '100vh',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Spin tip="Loading..." size="large" />
              </Row>
            )}
          </Router>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({ auth, language }) => ({
  auth,
  language,
});

const mapDispatchToProps = {
  signOutHandler: signOut,
  validateTokenHandler: validateToken,
  getAccountsHandler: getAccounts,
  getCountriesHandler: getCountries,
  getJobClassificationsHandler: getJobClassifications,
  getAdvertisersHandler: getAdvertisers,
  getWorkplaceProfileListHandler: getList,
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
