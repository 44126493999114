import React, { useState, useEffect } from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createJobAPI } from '../../../../services/jobs';
import { Steps, Button, notification } from 'antd';

import NewJobWizard01Container from './new_job_wizard/NewJobWizard01';
import NewJobWizard02Container from './new_job_wizard/NewJobWizard02';
import NewJobWizard03Container from './new_job_wizard/NewJobWizard03';

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right',
  },
};

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const { Step } = Steps;

function JobNewPageComponent(props) {
  const { classes, history, countries, jobClassifications, workProfileList } = props;
  const [newJob, setNewJob] = useState({
    record_created: '',
    workplace_profile_id: null,
    job_start: new Date(),
    job_expire: new Date(),
    job_title: '',
    short_description: '',
    long_description: '',
    work_option_full_time: 0,
    work_option_part_time: 0,
    work_option_casual: 0,
    remote_work: 0,
    location_country_id: null,
    location_region: null,
    salary_low: 0,
    salary_exact: 0,
    salary_high: 10000,
    advertisement_text: '',
    advertisement_summary: '',
    job_tasks: '',
    job_responsibilities: '',
    job_benefits: '',
    job_classification_id: null,
    platinum_tick_job: true,
  });

  const [fieldStatus, setFieldStatus] = useState({
    job_title: '',
    short_description: '',
    workplace_profile_id: '',
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [stepStatusError, setStepStatusError] = useState(false);

  const steps = [
    {
      title: 'Description',
      content: (
        <NewJobWizard01Container
          newJob={newJob}
          setNewJob={setNewJob}
          countries={countries}
          workProfileList={workProfileList}
          jobClassifications={jobClassifications}
          fieldStatus={fieldStatus}
        />
      ),
    },
    {
      title: 'Details',
      content: <NewJobWizard02Container newJob={newJob} setNewJob={setNewJob} />,
    },
    {
      title: 'Advertisement',
      content: <NewJobWizard03Container newJob={newJob} setNewJob={setNewJob} />,
    },
  ];

  useEffect(() => {
    setFieldStatus({});
  }, [newJob]);
  const validateStep1 = () => {
    setFieldStatus((prev) => ({
      ...prev,
      job_title: newJob.job_title ? '' : 'error',
      short_description: newJob.short_description ? '' : 'error',
      job_classification_id: newJob.job_classification_id ? null : 'error',
      workplace_profile_id: newJob.workplace_profile_id ? null : 'error',
    }));
    return newJob.job_title !== '' && newJob.short_description !== '' && newJob.job_classification_id !== null;
  };

  const nextStep = () => {
    let validate = true;
    switch (currentStep) {
      case 0:
        validate = validateStep1();
        break;
      default:
    }

    if (!validate) {
      setStepStatusError(true);
    } else {
      setStepStatusError(false);
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prev_step = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleSubmit = () => {
    openNotificationWithIcon('info', 'In Process', 'Creating new job in database.');
    createJobAPI(newJob)
      .then((data) => {
        openNotificationWithIcon('success', 'Job', 'Success creating new job!');
        history.push('/jobs/list');
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Job', 'Unable to create new job!');
      });
  };

  return (
    <React.Fragment>
      <Steps current={currentStep} status={stepStatusError ? 'error' : ''}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className={classes.stepsContent}>{steps[currentStep].content}</div>
      <div className={classes.stepsAction}>
        {currentStep > 0 && (
          <Button style={{ marginLeft: 8 }} onClick={() => prev_step()}>
            Previous
          </Button>
        )}
        {currentStep < steps.length - 1 && (
          <Button type="primary" onClick={() => nextStep()}>
            Next
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <Button type="primary" onClick={handleSubmit}>
            Done
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    app_state: state.app_state,
    jobs: state.jobs.jobs,
    language: state.language,
    countries: state.countries,
    jobClassifications: state.jobClassifications,
    workProfileList: state.workProfile.workProfile,
  };
};

const mapDispatchToProps = {};

const JobNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(JobNewPageComponent)));

export default JobNewPageComponentContainer;
