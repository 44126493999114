export const ADMINS_START_NEW_STAFF_CREATION = 'ADMINS_START_NEW_STAFF_CREATION';
export const ADMINS_UPDATE_NEW_STAFF_CREATION = 'ADMINS_UPDATE_NEW_STAFF_CREATION';

export function startNewStaffCreation() {
  return { type: ADMINS_START_NEW_STAFF_CREATION };
}

export function updateNewStaffCreation(key, value) {
  return {
    type: ADMINS_UPDATE_NEW_STAFF_CREATION,
    value: {
      key,
      value,
    },
  };
}
