import React, { useState } from 'react';
import withStyles from 'react-jss';
import { withRouter } from 'react-router-dom';
import { Tabs } from 'antd';

import AdvertiserSummary from './AdvertiserSummary';
import AdvertiserContact from './AdvertiserContact';
import AdvertiserAddress from './AdvertiserAddress';
import AdvertiserBilling from './AdvertiserBilling';
import ProductBalances from './ProductBalances';
import AccountHistory from './AccountHistory';

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right',
  },
};
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

function AdvertiserViewPageBody(props) {
  const { client_id, tab } = props;
  const [step, setStep] = useState(0);
  return (
    <React.Fragment>
      <Tabs defaultActiveKey={tab === 'billing' ? '4' : '1'} onChange={callback}>
        <TabPane tab="Summary" key="1">
          <AdvertiserSummary client_id={client_id} history={props.history} />
        </TabPane>
        <TabPane tab="Contact" key="2">
          <AdvertiserContact client_id={client_id} history={props.history} />
        </TabPane>
        <TabPane tab="Address" key="3">
          <AdvertiserAddress client_id={client_id} history={props.history} />
        </TabPane>
        <TabPane tab="Billing" key="4">
          <AdvertiserBilling client_id={client_id} history={props.history} />
        </TabPane>
        <TabPane tab="Account Balance" key="5">
          <ProductBalances client_id={client_id} history={props.history} />
        </TabPane>
        <TabPane tab="Account History" key="6">
          <AccountHistory client_id={client_id} history={props.history} />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
}

export default withRouter(withStyles(styles)(AdvertiserViewPageBody));
