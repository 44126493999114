
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, PageHeader, Button } from 'antd';

import AdvertiserListPageBody from './AdvertiserListPageBody';

function AdvertiserListPage (props) {
  const { history, advertisersList } = props;

  const handle_show_all = () => {
    history.push('/advertisers/list/');
  }

  const handle_new_advertisers = () => {
    history.push('/advertisers/new');
  }

  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title="Advertiser Management"
      />
      <Card
        style={{ minHeight: 360 }}
        title="Current Advertiser List"
        extra={[
          <Button key="3" onClick={handle_new_advertisers}>Create New</Button>,
          <Button key="2" disabled>View Record</Button>,
          <Button key="1" onClick={handle_show_all} type="primary">Show All</Button>,
        ]}
      >
        <div style={{ paddingTop: 24 }}>
          <AdvertiserListPageBody advertisersList = {advertisersList} />
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    advertisersList: state.advertisers.advertisers, // Advertisers Action Store
  };
};
const mapDispatchToProps = ({
})

export default connect(mapStateToProps, mapDispatchToProps) (AdvertiserListPage);
