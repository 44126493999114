import React, { Component } from 'react';
import { Table, Input, Button } from 'antd';

import { API } from 'aws-amplify';
import { NavLink } from 'react-router-dom';

import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

class AccountListPageBody extends Component {
  constructor(props) {
    super(props);
    this.state = { dataSource: [] };
    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
        defaultSortOrder: 'descend',
        render: (text) => <a>{text.toString().padStart(5, '0')}</a>,
      },
      {
        title: 'Name',
        dataIndex: 'file_by',
        ...this.getColumnSearchProps('file_by'),
      },
      {
        title: 'User Admin ID',
        dataIndex: 'admin_user_id',
        ...this.getColumnSearchProps('admin_user_id'),
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => (
          <span>
            <NavLink to={`/account/view/${record.id}`}>View</NavLink>
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    API.get('accounts', '/list')
      .then((response) => {
        const dataSource = [];
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            file_by: response.payload[i].file_by,
            admin_user_id: response.payload[i].admin_user_id,
          });
        }
        console.log(dataSource);
        this.setState({ dataSource: dataSource });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // TABLE SEARCH
  // ========================================================================

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: 8,
          }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text?.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  // ========================================================================

  render() {
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.state.dataSource}
          pagination={{
            pageSizeOptions: ['10', '20', '30'],
            showSizeChanger: true,
          }}
        />
      </React.Fragment>
    );
  }
}

export default AccountListPageBody;
