import React from 'react';
import {Row, Col, Form, DatePicker, Input, Select} from 'antd';
import moment from 'moment';
import CountrySeleteComponent from '../../../../common/CountrySeleteComponent';
const {Option} = Select;

const dateFormat = 'YYYY/MM/DD';

function NewJobWizard01Container(props) {
  const {fieldStatus, newJob, setNewJob, countries, jobClassifications, workProfileList} = props;
  const handleChange = event => {
    const {id, value} = event.target;
    setNewJob(prev => ({
      ...prev,
      [id]: value
    }));
  };
  const handleJobStartChange = (date, dateString) => {
    if (date && dateString)
      setNewJob(prev => ({
        ...prev,
        job_start: dateString
      }));
  };

  const handleChangeCountryId = value => {
    setNewJob(prev => ({
      ...prev,
      location_country_id: value
    }));
  };

  const handleChangeJobClassificationId = value => {
    setNewJob(prev => ({
      ...prev,
      job_classification_id: value
    }));
  };

  const handleChangeWorkplaceProfile = value => {
    setNewJob(prev => ({
      ...prev,
      workplace_profile_id: value
    }));
  };

  const handleJobExpireChange = (date, dateString) => {
    if (date && dateString)
      setNewJob(prev => ({
        ...prev,
        job_expire: dateString
      }));
  };

  console.log(countries, jobClassifications);

  const options = countries.countries?.map((d, index) => (
    <Option key={`countries-${index}`} value={d.id}>
      {d.full_name}
    </Option>
  ));
  const jobClassification = jobClassifications?.map((d, index) => (
    <Option key={`jobClassifications-${index}`} value={d.id}>
      {d.file_by}
    </Option>
  ));
  const workplaceProfiles = workProfileList?.map(workplaceProfile => (
    <Option key={workplaceProfile} value={workplaceProfile.id}>
      {workplaceProfile?.workplace_name}
    </Option>
  ));
  return (
    <React.Fragment>
      <Form layout='vertical'>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Form.Item label='Select the Workplace Profile'>
              <Select
                id='workplace_profile_id'
                showSearch
                value={newJob.workplace_profile_id}
                placeholder='Select Workplace Profile'
                showArrow={true}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={handleChangeWorkplaceProfile}
              >
                {workplaceProfiles}
              </Select>
              {fieldStatus.workplace_profile_id === 'error' ? (
                <div class='ant-form-item-explain ant-form-item-explain-error'>Workplace Profile is required</div>
              ) : (
                ''
              )}
            </Form.Item>
            <Form.Item
              label='Job Title'
              style={{marginBottom: 0}}
              validateStatus={fieldStatus.job_title}
              hasFeedback
              help={fieldStatus.job_title === 'error' ? 'Job Title is required' : ''}
            >
              <Input id='job_title' value={newJob.job_title} onChange={event => handleChange(event)} />
            </Form.Item>
            <Form.Item
              label='Short Description'
              validateStatus={fieldStatus.short_description}
              hasFeedback
              help={fieldStatus.short_description === 'error' ? 'Short Description is required' : ''}
            >
              <Input id='short_description' value={newJob.short_description} onChange={event => handleChange(event)} />
            </Form.Item>
            <Form.Item label='Job Category'>
              <Select
                id='job_classification_id'
                showSearch
                value={newJob.job_classification_id}
                placeholder='Select Job'
                showArrow={true}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={handleChangeJobClassificationId}
              >
                {jobClassification}
              </Select>
              {fieldStatus.job_classification_id === 'error' ? (
                <div class='ant-form-item-explain ant-form-item-explain-error'>Job Category is required</div>
              ) : (
                ''
              )}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label='Location Region'>
              <Input id='location_region' value={newJob.location_region} onChange={event => handleChange(event)} />
            </Form.Item>
            <Form.Item label='Location Country'>
              <CountrySeleteComponent
                id='location_country_id'
                value={newJob.location_country_id}
                handleChange={handleChangeCountryId}
              />
            </Form.Item>
            <Form.Item label='Advertiser Reference'>
              <Input
                id='advertiser_reference'
                value={newJob.advertiser_reference}
                onChange={event => handleChange(event)}
              />
            </Form.Item>
            <Row>
              <Form.Item label='Job start'>
                <DatePicker
                  value={moment(newJob.job_start, dateFormat)}
                  format={dateFormat}
                  onChange={handleJobStartChange}
                />
              </Form.Item>
              <Form.Item label='Job expire' style={{marginLeft: 30}}>
                <DatePicker
                  value={moment(newJob.job_expire, dateFormat)}
                  format={dateFormat}
                  onChange={handleJobExpireChange}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default NewJobWizard01Container;
