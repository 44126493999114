import React, { Component } from 'react';
import { Card, PageHeader, Button } from 'antd';

import AccountNewPageBody from './body';

function AccountNewPage(props) {
  const { history } = props;
  const handleShowAll = () => {
    history.push('/account/list/');
  };

  const handleNewAccounts = () => {
    history.push('/account/new');
  };

  return (
    <div
      style={{
        padding: 24,
        background: '#fff',
        minHeight: 360,
      }}
    >
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16,
        }}
        title="Account Management"
      />
      <Card
        style={{ minHeight: 360 }}
        title="Create a New Account Record"
        extra={[
          <Button key="3" onClick={handleNewAccounts} type="primary">
            Create New
          </Button>,
          <Button key="2" disabled>
            View Record
          </Button>,
          <Button key="1" onClick={handleShowAll}>
            Show All
          </Button>,
        ]}
      >
        <div style={{ paddingTop: 24 }}>
          <AccountNewPageBody />
        </div>
      </Card>
    </div>
  );
}

export default AccountNewPage;
