import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Button, Form, Input, Select, Checkbox, Upload, notification, Spin, message, PageHeader, Card } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Utils } from '../../../helpers';
import APIUtils from '../../../helpers/api/utils';
import CountrySeleteComponent from '../../../components/common/CountrySeleteComponent';
import {
  getJobProfiles,
  updateJobProfiles,
  createJobProfiles
} from '../../../redux/actions/jobProfiles';

const { Option } = Select;
const { Dragger } = Upload;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const JobProfileView = (props) => {
  const { countries, user, getJobProfilesHandler, jobProfileStore, updateJobProfilesHandler, createJobProfilesHandler } = props;
  const [jobProfile, setJobProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getJobProfilesHandler(id)
    }
  }, []);

  useEffect(() => {
    const { pitch_video_file_id } = jobProfileStore;
    setJobProfile(jobProfileStore);
    if (jobProfileStore && pitch_video_file_id) {
      setVideoList([{
        ...JSON.parse(pitch_video_file_id),
        uid: 'applicant-uv-1'
      }
      ]);
    }
  }, [jobProfileStore])

  const onSubmit = () => {
    setLoading(true);
    if (jobProfileStore.id) {
      updateJobProfilesHandler(jobProfileStore.id, jobProfile)
        .then(data => {
          openNotificationWithIcon('success', 'Update Successful', 'Your Job Profile has been updated successfully');
          setLoading(false);
        }).catch(error => {
          openNotificationWithIcon('error', 'Update Failed', 'Your Job Profile has been updated failed!');
          setLoading(false);
        });
    } else {
      createJobProfilesHandler(user.id, jobProfile)
        .then(data => {
          openNotificationWithIcon('success', 'Create Successful', 'Your Job Profile has been created successfully');
          setLoading(false);
        }).catch(error => {
          openNotificationWithIcon('error', 'Create Failed', 'Your Job Profile has been created failed!');
          setLoading(false);
        });
    }
  };

  const handleChangeCheckbox = event => {
    const { checked, id } = event.target;
    setJobProfile(prev => ({
      ...prev,
      [id]: checked
    }));
  }

  const handleChange = event => {
    const { id, value } = event.target;
    setJobProfile(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const handleChangeStreetAddress = (value) => {
    setJobProfile(prev => ({
      ...prev,
      street_address_country_id: value
    }));
  };

  const token = APIUtils.getAccessToken();

  const uploadVideoProps = {
    name: 'file',
    multiple: false,
    accept: '.webm, .mkv, .flv, .vob, .mp4, .ogv, .ogg, .drc, .avi, .mov, .wmv,	.rmvb, .rm, .m4p, .m4v, .mpg, mp2, .mpeg, .mpe, .mpv, .flv .f4v .f4p .f4a .f4b, .3g2, .3gp, .svi',
    action: `${Utils.serverUrl.API_BASE_URL}/applicant/v1/upload`,
    headers: {
      'Authorization': `Bearer ${token}`
    },
    fileList: videoList,
    onChange(info) {
      const { status } = info.file;
      setVideoList([info.file]);
      if (status !== 'uploading') {
        setJobProfile(prev => ({
          ...prev,
          pitch_video_file_id: JSON.stringify({
            name: info.file.name,
            path: info.file.response.Location
          })
        }));
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        if (jobProfile.pitch_video_file_id)
          setVideoList([JSON.parse(jobProfile.pitch_video_file_id)]);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  }

  const options = countries?.map((d, index) => <Option key={`countries-${index}`} value={d.id} >{d.full_name}</Option>);

  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='My Job Profile'
      />
      <Card
        style={{ minHeight: 360 }}
        title='My Job Profile'
      >
        <React.Fragment>
          <Spin spinning={loading}>
            <Form
              layout='vertical'
              onFinish={onSubmit}
              style={{ paddingTop: 20 }}
            >
              <Row gutter={[96, 24]}>
                <Col>
                  <Form.Item label="First Name">
                    <Input
                      id="first_name"
                      value={jobProfile?.first_name}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item label="Last Name">
                    <Input
                      id="last_name"
                      value={jobProfile?.last_name}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                  >
                    <Input
                      id="email"
                      value={jobProfile?.email}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[96, 24]}>
                <Col xs={12}>
                  <Form.Item label="Street Address (Line 1)">
                    <Input
                      id="street_address_line_1"
                      value={jobProfile?.street_address_line_1}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Form.Item label="Street Address (Line 2)">
                    <Input
                      id="street_address_line_2"
                      value={jobProfile?.street_address_line_2}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Row gutter={[12, 0]}>
                    <Col xs={12}>
                      <Form.Item label="Region">
                        <Input
                          id="street_address_suburb"
                          value={jobProfile?.street_address_suburb}
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={8}>
                      <Form.Item label="State">
                        <Input
                          id="street_address_state"
                          value={jobProfile?.street_address_state}
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={4}>
                      <Form.Item label="PostCode"
                      >
                        <Input
                          id="street_address_postcode"
                          value={jobProfile?.street_address_postcode}
                          onChange={event => handleChange(event)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item label="Country">
                    <CountrySeleteComponent
                      id="street_address_country_id"
                      value={jobProfile?.street_address_country_id}
                      handleChange={handleChangeStreetAddress} />
                  </Form.Item>
                  <Form.Item label="Telephone Mobile" >
                    <Input
                      id="telephone_mobile"
                      value={jobProfile?.telephone_mobile}
                      onChange={event => handleChange(event)}
                    />
                  </Form.Item>
                  <Form.Item label="Make my profile visible to:" >
                    <Checkbox
                      style={{ display: 'block' }}
                      id='visible_search'
                      onChange={handleChangeCheckbox}
                      checked={jobProfile?.visible_search}>
                      Head Hunters (I am open to an offer)
                    </Checkbox>
                    <Checkbox
                      id='visible_applications'
                      style={{ marginLeft: 0, marginTop: 5 }}
                      onChange={handleChangeCheckbox}
                      checked={jobProfile?.visible_applications}>
                      People I send my application to
                    </Checkbox>
                  </Form.Item>
                  <Form.Item label="Make my Pitch Video visible to:" >
                    <Checkbox
                      id='video_visible_search'
                      style={{ display: 'block' }}
                      onChange={handleChangeCheckbox}
                      checked={jobProfile?.video_visible_search}>
                      Head Hunters (I am open to an offer)
                    </Checkbox>
                    <Checkbox
                      id='video_visible_applications'
                      style={{ marginLeft: 0, marginTop: 5 }}
                      onChange={handleChangeCheckbox}
                      checked={jobProfile?.video_visible_applications}>
                      People I send my application to
                    </Checkbox>
                  </Form.Item>
                  <Form.Item label="Preferred Work Options" >
                    <Checkbox
                      style={{ display: 'block' }}
                      id='preferred_from_home'
                      onChange={handleChangeCheckbox}
                      checked={jobProfile?.preferred_from_home}>
                      Work from home
                    </Checkbox>
                    <Checkbox
                      style={{ display: 'block', marginLeft: 0, marginTop: 5 }}
                      id='preferred_full_time'
                      onChange={handleChangeCheckbox}
                      checked={jobProfile?.preferred_full_time}>
                      Full time
                    </Checkbox>
                    <Checkbox
                      style={{ display: 'block', marginLeft: 0, marginTop: 5 }}
                      id='preferred_part_time'
                      onChange={handleChangeCheckbox}
                      checked={jobProfile?.preferred_part_time}>
                      Part time
                    </Checkbox>
                    <Checkbox
                      style={{ display: 'block', marginLeft: 0, marginTop: 5 }}
                      id='preferred_flexible'
                      onChange={handleChangeCheckbox}
                      checked={jobProfile?.preferred_flexible}>
                      Flexible hours
                    </Checkbox>
                    <Checkbox
                      style={{ display: 'block', marginLeft: 0, marginTop: 5 }}
                      id='preferred_willing_relocate'
                      onChange={handleChangeCheckbox}
                      checked={jobProfile?.preferred_willing_relocate}>
                      Willing to Relocate
                    </Checkbox>
                  </Form.Item>

                  <Form.Item label="Target Salary (Min)" >
                    <Input
                      id="target_salary_min"
                      onChange={event => {
                        handleChange(event);
                      }}
                      value={jobProfile?.target_salary_min}
                    />
                  </Form.Item>
                  <Form.Item label="Target Salary (Max)" >
                    <Input
                      id="target_salary_max"
                      onChange={event => {
                        handleChange(event);
                      }}
                      value={jobProfile?.target_salary_max}
                    />
                  </Form.Item>
                  <Form.Item label="Platinum Tick Jobs Only" >
                    <Checkbox
                      id='platinum_tick_only'
                      onChange={handleChangeCheckbox}
                      checked={jobProfile?.platinum_tick_only} >
                      I am only interested in Platinum Tick jobs with a guarantee of a response from the site or advertiser
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    label="Video Upload"
                  >
                    <Dragger
                      {...uploadVideoProps}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text" style={{ padding: 10 }}>Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single video file only.
                  </p>
                    </Dragger>
                  </Form.Item>

                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Button
                      type='primary'
                      htmlType='submit'
                    >
                      Save Record
              </Button>
                  </Col>
                </Col>
              </Row>
            </Form>
          </Spin>
        </React.Fragment>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    countries: state.countries.countries,
    user: state.auth.currentUser,
    jobProfileStore: state.jobProfiles.jobProfiles
  }
};

const mapDispatchToProps = ({
  getJobProfilesHandler: getJobProfiles,
  updateJobProfilesHandler: updateJobProfiles,
  createJobProfilesHandler: createJobProfiles
});

export default connect(mapStateToProps, mapDispatchToProps)(JobProfileView);
