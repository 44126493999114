import { applicantType } from '../type';
import { getApplicantsAPI, getApplicant, updateApplicantAPI, createApplicantAPI } from '../../services/applicant';

export const APPLICANTS_START_NEW_APPLICANT_CREATION = 'APPLICANTS_START_NEW_APPLICANT_CREATION';
export const APPLICANTS_UPDATE_NEW_APPLICANT_CREATION = 'APPLICANTS_UPDATE_NEW_APPLICANT_CREATION';

export function startNewClientCreation() {
  return { type: APPLICANTS_START_NEW_APPLICANT_CREATION };
}

export function updateNewClientCreation(key, value) {
  return {
    type: APPLICANTS_UPDATE_NEW_APPLICANT_CREATION,
    value: {
      key,
      value,
    },
  };
}

/* Define New action  */
const getList = () => {
  return (dispatch) => {
    return getApplicantsAPI()
      .then((data) => {
        if (data) {
          return dispatch({
            type: applicantType.GET_APPLICANTS,
            applicants: data.data,
          });
        }
        throw new Error(data.msg);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

const getAplicant = (id) => {
  return getApplicant(id)
    .then((data) => {
      if (data) {
        return data.data;
      }
      throw new Error(data.msg);
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const updateAplicant = (id, param) => {
  return (dispatch) => {
    return updateApplicantAPI(id, param)
      .then((data) => {
        if (data) {
          return data.data;
        }
      })
      .catch((error) => {});
  };
};

const createApplicant = (param) => {
  return (dispatch) => {
    return createApplicantAPI(param)
      .then((data) => {
        if (data) {
          return data.data;
        }
      })
      .catch((error) => {});
  };
};

export { getList, getAplicant, updateAplicant, createApplicant };
