import { combineReducers } from 'redux';

import app_state from './app_state';

import advertisers from './advertisers';

import applicants from './applicants';
import language from './language';
import admins from './admins';
import accounts from './accounts';
import countries from './countries';
import workProfile from './workProfile';
import jobs from './jobs';
import jobClassifications from './job_classifications';
import auth from './auth';
import jobProfiles from './jobProfiles';
import billingAppReducer from './billing_applicants';
import billingAdvReducer from './billing_advertisers';
import advertProductsReducer from './advert_products';
import subscriptionsRedux from './subscriptions';

const rootReducer = combineReducers({
  app_state,
  admins,
  advertisers,
  applicants,
  jobProfiles,
  workProfile,
  language,
  accounts,
  jobs,
  countries: countries,
  jobClassifications,
  auth,
  billingApp: billingAppReducer,
  billingAdv: billingAdvReducer,
  advertProducts: advertProductsReducer,
  subscriptions: subscriptionsRedux,
});

export default rootReducer;
