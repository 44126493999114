import React, { useEffect, useState } from 'react';
import withStyles from 'react-jss'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tabs, notification, Spin } from 'antd';
import { updateUserProfile } from '../../../../redux/actions/auth';
import UserSummary from './UserSummary';
import UserContact from './UserContact';
import UserAddress from './UserAddress';

const queryString = require('query-string');
const { TabPane } = Tabs;

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right'
  },
};

function UserProfilePageBody(props) {
  const { location, app_state, history, user, updateUserProfileHandler, countries } = props;
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('summary');
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    setActiveTab(parsed.tab);
  }, [location.search]);

  const handleClickTab = (e) => {
    history.push(`/user-profile?tab=${e}`);
  }

  const openNotificationWithIcon = (type, header, message) => {
    notification[type]({
      message: header,
      description: message
    });
  };

  const updateUserProfileInfo = (updateData) => {
    setLoading(true);
    updateUserProfileHandler(updateData)
      .then(data => {
        setLoading(false);
        if (data) {
          openNotificationWithIcon('success', 'Save Success', 'User Info Updated.');
        } else {
          openNotificationWithIcon('error', 'Save Failed', 'An error occurred in User Info Updating.');
        }
      }).catch(error => {
        setLoading(false);
        openNotificationWithIcon('error', 'Save Failed', 'An error occurred in User Info Updating.');
      })
  };

  if (!app_state) {
    return null;
  }

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Tabs activeKey={activeTab} onTabClick={handleClickTab} defaultActiveKey={activeTab}>
          <TabPane tab="User Summary" key="summary">
            <UserSummary
              userProfile={user}
              updateUserProfileHandler={updateUserProfileInfo} />
          </TabPane>
          <TabPane tab="Contact" key="contact">
            <UserContact
              userProfile={user}
              updateUserProfileHandler={updateUserProfileInfo} />
          </TabPane>
          <TabPane tab="Address" key="address">
            <UserAddress
              userProfile={user}
              countries={countries}
              updateUserProfileHandler={updateUserProfileInfo} />
          </TabPane>
        </Tabs>
      </Spin>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    user: state.auth.currentUser,
    countries: state.countries.countries,
  };
};

const mapDispatchToProps = ({
  updateUserProfileHandler: updateUserProfile
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(UserProfilePageBody)));