import { UPDATE_CURRENT_USER, DATA_COUNTRIES, DATA_STAFF_STATUS } from '../actions/app_state';

const initialState = {
  current_user_id: null,
  current_user_record: {},
  current_admin_id: null,
  current_admin_record: {},
  admin_access: [],
};

function app_state(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        current_user_id: action.current_user_id,
        current_user_record: action.current_user_record,
        current_admin_id: action.current_admin_id,
        current_admin_record: action.current_admin_record,
        admin_access: action.admin_access,
      };

    case DATA_COUNTRIES:
      return {
        ...state,
        data_countries_list: action.countries,
      };
    case DATA_STAFF_STATUS:
      return {
        ...state,
        data_admin_status_list: action.data_admin_status_list,
      };
    default:
      return state;
  }
}

export default app_state;
