import React, {Component} from 'react';
import {Card, PageHeader, Button} from 'antd';

import SubscriptionsListPageComponent from './SubscriptinosListPageComponent';

class SubscriptionsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          padding: 24,
          background: '#fff',
          minHeight: 360
        }}
      >
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title='Subscriptions'
        />
        <div style={{paddingTop: 24}}>
          <SubscriptionsListPageComponent />
        </div>
      </div>
    );
  }
}

export default SubscriptionsListPage;
