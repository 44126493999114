import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

/* Define the Advertiser Endpoint */
const getJobsAPI = () => {
  const path = '/admin/v1/jobs';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const getJobsStatusList = () => {
  const path = '/advertiser/v1/jobs/status-list';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const getJobAPI = (id) => {
  const path = `/admin/v1/jobs/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const updateJobAPI = (id, params) => {
  const path = `/admin/v1/jobs/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const createJobAPI = (params) => {
  const path = '/admin/v1/jobs/create';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

export { getJobsAPI, getJobAPI, updateJobAPI, createJobAPI, getJobsStatusList };
