import { billingAppType } from '../type';
import { getBillingAppAPI, getAllBillingAppAPI } from '../../services/billing_applicants';

/* Define New action  */
const getAllBillingApp = () => {
  return (dispatch) => {
    return getAllBillingAppAPI()
      .then((data) => {
        if (data) {
          return dispatch({
            type: billingAppType.GET_ALL_APP_SUB,
            allBillingApp: data.data,
          });
        } // TODO Error Action Calling
      })
      .catch((error) => {
        // TODO Error Action Calling
      });
  };
};

const getBillingApp = (id) => {
  return (dispatch) => {
    return getBillingAppAPI(id)
      .then((data) => {
        if (data) {
          return dispatch({
            type: billingAppType.GET_APP_SUB,
            selectedBillingApp: data.data,
          });
        } // TODO Error Action Calling
      })
      .catch((error) => {
        // TODO Error Action Calling
      });
  };
};

export { getAllBillingApp, getBillingApp };
