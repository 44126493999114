import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

/* Define the Accounts Endpoint */
const getListAPI = () => {
  const path = '/all/v1/countries';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const getCountryAPI = (id) => {
  const path = `/all/v1/country/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};
export { getListAPI, getCountryAPI };
