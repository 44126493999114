import APIUtils from '../../helpers/api/utils';
import { loginAPI, signupAPI, validateTokenAPI, getUserAPI, updateUserAPI } from '../../services/auth';
import { authActionType, userProfileType } from '../type';

const signIn = (userData) => {
  return (dispatch) => {
    return loginAPI(userData)
      .then((data) => {
        const { token } = data;
        APIUtils.setAccessToken(token);

        if (data) {
          return dispatch({
            type: authActionType.SIGN_IN,
            user: data.user,
          });
        } else throw new Error(data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const signUp = (stateUser) => {
  return (dispatch) => {
    return signupAPI(stateUser)
      .then((data) => {
        const { token } = data;
        APIUtils.setAccessToken(token);

        if (data) {
          return dispatch({
            type: authActionType.SIGN_UP,
            user: data.user,
          });
        } else throw new Error(data.message);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

/* Token Validation Action */
const validateToken = () => {
  const token = APIUtils.getAccessToken();
  return (dispatch) => {
    return validateTokenAPI({ token })
      .then((data) => {
        const { isvalid, user } = data;
        return dispatch({
          type: authActionType.AUTH_VALID,
          isAuthenticated: isvalid,
          user: user,
        });
      })
      .catch((error) => {
        APIUtils.setAccessToken(null);
        return false;
      });
  };
};

const signOut = () => {
  APIUtils.setAccessToken(null);
  return {
    type: authActionType.SIGN_OUT,
    user: {},
  };
};

const getUserProfile = () => {
  return (dispatch) => {
    return getUserAPI()
      .then((data) => {
        if (data) {
          return dispatch({
            type: userProfileType.GET_USER_PROFILE,
            user: data.user,
          });
        }
        console.log(data.msg);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const updateUserProfile = (params) => {
  return (dispatch) => {
    return updateUserAPI(params)
      .then((data) => {
        if (data) {
          return dispatch({
            type: userProfileType.UPDATE_USER_PROFILE,
            user: data.user,
          });
        }
        console.log(data.msg);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export { signIn, signOut, signUp, validateToken, getUserProfile, updateUserProfile };
