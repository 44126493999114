import DashboardPage from '../views/dashboard/dashboard_page';
import UserProfilePage from '../views/dashboard/user_profile_page';
import { AdvertiserNewPage, AdvertiserListPage, AdvertiserViewPage } from '../views/dashboard/advertisers';
import { ApplicantNewPage, ApplicantListPage, ApplicantViewPage } from '../views/dashboard/applicants';
import { AccountListPage, AccountNewPage, AccountViewPage } from '../views/dashboard/accounts';
import { JobListPage, JobNewPage, JobViewPage } from '../views/dashboard/jobs';

import { BillingApplicantListPage } from '../views/dashboard/billing/applicants';
import { BillingAdvertiserListPage } from '../views/dashboard/billing/advertisers';
import ApplicantSubscriptionView from '../views/dashboard/billing/applicants/itemView';
import { SubscriptionsListPage } from '../views/dashboard/billing/subscriptions';
import SubscriptionView from '../views/dashboard/billing/subscriptions/itemView';
import AdvertProjectListPage from '../views/dashboard/billing/advert';
import AdvertProjectViewPage from '../views/dashboard/billing/advert/AdvertProjectViewPage';
import { WorkProfileListPage, WorkProfileViewPage } from '../views/dashboard/workProfile';
import { JobProfileListPage, JobProfileView } from '../views/dashboard/jobProfiles';
import LegalPage from '../views/dashboard/LegalPage';
const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage,
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    component: UserProfilePage,
  },
  {
    path: '/advertisers/new',
    name: 'New Advertiser',
    component: AdvertiserNewPage,
  },
  {
    path: '/advertisers/list',
    name: 'Advertiser List',
    component: AdvertiserListPage,
  },
  {
    path: '/advertisers/view/:client_id',
    name: 'View Advertiser',
    component: AdvertiserViewPage,
  },
  {
    path: '/applicants/new',
    name: 'New Applicant',
    component: ApplicantNewPage,
  },
  {
    path: '/applicants/list',
    name: 'Applicant List',
    component: ApplicantListPage,
  },
  {
    path: '/applicants/view/:client_id',
    name: 'View Applicant',
    component: ApplicantViewPage,
  },
  {
    path: '/jobs/new',
    name: 'Job New',
    component: JobNewPage,
  },
  {
    path: '/jobs/list',
    name: 'Job List',
    component: JobListPage,
  },
  {
    path: '/jobs/view/:job_id',
    name: 'Job View',
    component: JobViewPage,
  },
  {
    path: '/legal',
    name: 'Legal',
    component: LegalPage,
  },
  {
    path: '/applicants/job-profiles/:id',
    name: 'Job Profile',
    component: JobProfileView,
  },
  {
    path: '/applicants/job-profiles',
    name: 'Job Profile',
    component: JobProfileListPage,
  },
  {
    path: '/billing/applicants/view/:id',
    name: 'Applicant Subscription View',
    component: ApplicantSubscriptionView,
  },
  {
    path: '/billing/applicants/list',
    name: 'Subscription List',
    component: BillingApplicantListPage,
  },
  {
    path: '/billing/advertisers/list',
    name: 'Subscription List',
    component: BillingAdvertiserListPage,
  },
  {
    path: '/billing/advert-products/view/:id',
    name: 'Advertisement Product View',
    component: AdvertProjectViewPage,
  },
  {
    path: '/billing/advert-products/list',
    name: 'Advertisement Product List',
    component: AdvertProjectListPage,
  },
  {
    path: '/billing/subscriptions/view/:id',
    name: 'Subscription Item View',
    component: SubscriptionView,
  },
  {
    path: '/billing/subscriptions/list',
    name: 'Subscription List',
    component: SubscriptionsListPage,
  },
  {
    path: '/advertisers/workplace-profiles/view/:client_id',
    name: 'WorkProfile List',
    component: WorkProfileViewPage,
  },
  {
    path: '/advertisers/workplace-profiles',
    name: 'WorkProfile List',
    component: WorkProfileListPage,
  },
  {
    path: '/account/new',
    name: 'Account New',
    component: AccountNewPage,
  },
  {
    path: '/account/list',
    name: 'Account List',
    component: AccountListPage,
  },
  {
    path: '/account/view/:account_id',
    name: 'Account View',
    component: AccountViewPage,
  },
  {
    redirect: true,
    path: '/',
    pathTo: '/dashboard',
    name: 'Dashboard',
  },
];

export default dashRoutes;
