import { jobProfilesType } from '../type';

const initialState = {
  jobProfiles: {},
  jobProfilesList: [],
};

export default function jobProfileReducer(state = initialState, action) {
  switch (action.type) {
    case jobProfilesType.CREATE_JOB_PROFILES:
      return {
        ...state,
        jobProfiles: action.jobProfiles,
      };
    case jobProfilesType.GET_JOB_PROFILES:
      return {
        ...state,
        jobProfiles: action.jobProfiles,
      };
    case jobProfilesType.UPDATE_JOB_PROFILES:
      return {
        ...state,
        jobProfiles: action.jobProfiles,
      };
    case jobProfilesType.GET_JOB_PROFILES_LIST:
    default:
      return {
        ...state,
        jobProfilesList: action.jobProfilesList,
      };
  }
}
