import React, {useEffect} from 'react';

import {Table, Tag} from 'antd';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';

import listHOC from '../../../common/listHOC';
import {getJobProfilesList} from '../../../../redux/actions/jobProfiles';

const JobProfilesListPageComponent = ({getJobProfilesListHandler, jobProfiles}) => {
  useEffect(() => {
    getJobProfilesListHandler();
  }, []);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      render: id => <NavLink to={`/applicants/job-profiles/${id}`}>{id}</NavLink>
    },
    {
      title: 'First Name',
      dataIndex: 'first_name'
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },

    {
      title: 'PTO',
      dataIndex: 'platinum_tick_only',
      filters: [
        {
          text: 'PTO',
          value: 1
        },
        {
          text: 'No PTO',
          value: 0
        }
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.platinum_tick_only == value,
      render: (text, record) => {
        switch (text) {
          case 1:
            return <Tag color='cyan'> Yes </Tag>;
          case 0:
            return <Tag color='magenta'> No </Tag>;
          default:
            return '';
        }
      }
    }
  ];
  return (
    <React.Fragment>
      <Table columns={columns} dataSource={jobProfiles} />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    jobProfiles: state.jobProfiles.jobProfilesList,
    language: state.language
  };
};

const mapDispatchToProps = {getJobProfilesListHandler: getJobProfilesList};

export default connect(mapStateToProps, mapDispatchToProps)(listHOC(JobProfilesListPageComponent));
