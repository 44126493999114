import React, {Component} from 'react';
import {Card, PageHeader, Button} from 'antd';

import AdvertProjectListComponent from './AdvertProjectListComponent';

class AdvertProjectListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickShowAll = () => {
    // this.props.history.push('/subscriptions/advertisers/list/');
  };

  onClickNewSubscription = () => {
    // this.props.history.push('/subscriptions/advertisers/new');
  };

  render() {
    return (
      <div
        style={{
          padding: 24,
          background: '#fff',
          minHeight: 360
        }}
      >
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title='Advertisement Product Management'
        />
        <Card
          style={{minHeight: 360}}
          title='Advertisement Product Management'
        >
          <div style={{paddingTop: 24}}>
            <AdvertProjectListComponent />
          </div>
        </Card>
      </div>
    );
  }
}

export default AdvertProjectListPage;
