
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, notification, Form, Input, Typography } from 'antd';
import { updateAplicant } from '../../../../../redux/actions/applicants';
const { Title } = Typography;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class ApplicantContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tel_work: '',
      tel_home: '',
      tel_mobile: '',
      email_secondary: ''
    }
  }

  async componentDidMount() {
    const applicant = this.props.applicants.find(item => item.id == this.props.client_id);
    this.setState({...applicant});
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value});
  };

  handleSubmit = async event => {
    event.preventDefault();
    const {
      tel_work,
      tel_home,
      tel_mobile,
      email_secondary
    } = this.state;
    this.setState({ isLoading: true });
    this.props.updateAplicantHandler(this.props.client_id, {
      tel_work,
      tel_home,
      tel_mobile,
      email_secondary
    })
      .then(data => {
        this.setState({
          tel_work: data.tel_work,
          tel_home: data.tel_home,
          tel_mobile: data.tel_mobile,
          email_secondary: data.email_secondary
        });
        this.setState({ isLoading: false });
      }).catch(erorr => {
        this.setState({ isLoading: false });
      })
  };

  render() {
    console.log(this.state);

    return (
      <React.Fragment>
        <Form
          layout="vertical"
          onSubmit={this.handleSubmit}
        >
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Title level={4}>Contact Details</Title>
              <Form.Item label="Telephone (home)" style={{ marginBottom: 0 }}>
                <Input
                  id="tel_home"
                  value={this.state.tel_home}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Telephone (work)" style={{ marginBottom: 0 }}>
                <Input
                  id="tel_work"
                  value={this.state.tel_work}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Telephone (mobile)" style={{ marginBottom: 0 }}>
                <Input
                  id="tel_mobile"
                  value={this.state.tel_mobile}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Primary Email" style={{ marginBottom: 0 }}>
                <Input
                  id="email"
                  value={this.state.email}
                  disabled
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Secondary Email" style={{ marginBottom: 0 }}>
                <Input
                  id="email_secondary"
                  value={this.state.email_secondary}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    applicants: state.applicants.applicants,
  };
};

const mapDispatchToProps = ({
  updateAplicantHandler: updateAplicant
})

const ApplicantContactContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantContact);

export default ApplicantContactContainer;

