import React, {
  useState,
  useEffect
} from 'react';

import {
  NavLink
} from 'react-router-dom';
import { Table, Spin, Typography } from 'antd';
import { connect } from 'react-redux';

import listHOC from '../../../../components/common/listHOC';
import { getAdertProductList } from '../../../../redux/actions/advert_products';

const { Text } = Typography;
const AdvertProjectListComponent = (props) => {

  const { getAdertProductListHandler, allAdvertProducts } = props;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadingAPIs();
  }, []);
  
  const loadingAPIs = async () => {
    setLoading(true);
    await getAdertProductListHandler();
    setLoading(false);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (highlighter, record) => <NavLink to={`/billing/advert-products/view/${record.id}`}>{record.id}</NavLink>
    },
    {
      title: 'Advert Name',
      dataIndex: 'advert_name',
    },
    {
      title: 'Retail Price',
      dataIndex: 'retail_price',
      render: (highlighter, record) => <Text>${(record.retail_price / 100).toFixed(2)}</Text>,
    }
  ];

  return <React.Fragment>
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={allAdvertProducts}
      />
    </Spin>
  </React.Fragment>;
};

const mapStateToProps = state => {
  return {
    allAdvertProducts: state.advertProducts.allAdvertProducts
  };
};


const mapDispatchToProps = ({
  getAdertProductListHandler: getAdertProductList,
});

export default connect(mapStateToProps, mapDispatchToProps)(listHOC(AdvertProjectListComponent));
