import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Card, Button, Row, Col } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import { signUp } from '../../redux/actions/auth';

class LoginPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      email_help: '',
      email_status: '',
      new_password: '',
      new_password_help: '',
      new_password_status: '',
      confirm_password: '',
      confirm_password_help: '',
      confirm_password_status: '',
    };
  }

  componentDidMount() {}

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });

    if (event.target.id === 'email') {
      this.setState({
        email_status: 'success',
        email_help: ' ',
      });
    }

    if (event.target.id === 'password') {
      this.setState({
        new_password_status: 'success',
        new_password_help: ' ',
      });
    }
  };

  validate_form = () => {
    let valid = true;
    if (this.state.email.length < 1) {
      this.setState({
        email_status: 'error',
        email_help: 'Email is required',
      });
      valid = false;
    }

    if (this.state.new_password.length < 1) {
      this.setState({
        password_status: 'error',
        password_help: 'Password is required',
      });
      valid = false;
    }

    if (this.state.new_password.length > 0 && this.state.email.length > 0) {
      this.setState({
        email_status: 'success',
        password_status: 'success',
        email_help: ' ',
        password_help: ' ',
      });
    }

    if (this.state.new_password) {
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/;
      if (re.test(String(this.state.new_password))) {
        this.setState({
          new_password_help: null,
          new_password_status: 'success',
        });
      } else {
        valid = false;
        this.setState({
          new_password_help: '8 characters long including uppercase, lowercase, a number and a symbol',
          new_password_status: 'error',
        });
      }
    } else {
      valid = false;
      this.setState({
        new_password_help: 'Please provide a password',
        new_password_status: 'error',
      });
    }

    if (this.state.new_password !== this.state.confirm_password) {
      valid = false;
      this.setState({
        confirm_password_help: 'Must be the same password',
        confirm_password_status: 'error',
      });
    }

    return valid;
  };

  handleSubmit = () => {
    this.setState({
      verify_code_help: '',
      verify_code_status: '',
      new_password_help: '',
      new_password_status: '',
      confirm_password_help: '',
      confirm_password_status: '',
    });
    if (!this.validate_form()) {
      return;
    }
    this.props.signUpHandler({
      email: this.state.email.toLowerCase(),
      password: this.state.new_password,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col span={12}>
              <Card title="Sign Up" style={{ maxWidth: 600 }}>
                <Form style={{ marginTop: 20 }}>
                  <Form.Item hasFeedback help={this.state.email_help} validateStatus={this.state.email_status}>
                    <Input
                      placeholder="Email"
                      id="email"
                      value={this.state.email}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    help={this.state.new_password_help}
                    validateStatus={this.state.new_password_status}
                  >
                    <Input.Password
                      placeholder="New Password"
                      id="new_password"
                      value={this.state.new_password}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    help={this.state.confirm_password_help}
                    validateStatus={this.state.confirm_password_status}
                  >
                    <Input.Password
                      placeholder="Confirm Password"
                      id="confirm_password"
                      value={this.state.confirm_password}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" style={{ width: '100%' }} onClick={this.handleSubmit}>
                      Sign Up
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = { signUpHandler: signUp };

export default injectIntl(withRouter(connect(null, mapDispatchToProps)(LoginPageComponent)));
