import { advertProductsType } from '../type';
import { getAdertProductListAPI, getAdertProductAPI } from '../../services/advert_products';

const getAdertProductList = () => {
  return (dispatch) => {
    return getAdertProductListAPI()
      .then((data) => {
        if (data) {
          return dispatch({
            type: advertProductsType.GET_ALL_ADVERT_PRODUCTS,
            allAdvertProducts: data.data,
          });
        } // TODO Error Action Calling
      })
      .catch((error) => {
        // TODO Error Action Calling
      });
  };
};

const getAdertProduct = (id) => {
  return (dispatch) => {
    return getAdertProductAPI(id)
      .then((data) => {
        if (data) {
          return dispatch({
            type: advertProductsType.GET_ADVERT_PRODUCT,
            selectedAdvertProduct: data.data,
          });
        } // TODO Error Action Calling
      })
      .catch((error) => {
        // TODO Error Action Calling
      });
  };
};

export { getAdertProductList, getAdertProduct };
