/* Definded the action type for advertisers */
import billingAppType from '../type/billing_applicants';

const initialState = {
  /* Advertisers List Store*/
  allBillingApp: [],
  selectedBillingApp: {},
};

function billingAppReducer(state = initialState, action) {
  switch (action.type) {
    /* Advertiser Reducer to save the advertisers list */
    case billingAppType.GET_ALL_APP_SUB:
      return {
        ...state,
        allBillingApp: action.allBillingApp,
      };
    case billingAppType.GET_APP_SUB:
      return {
        ...state,
        selectedBillingApp: action.selectedBillingApp,
      };
    default:
      return state;
  }
}

export default billingAppReducer;
