import { workProfileType } from '../type';
import { getWorkProfilesAPI, getWorkProfile, updateWorkProfileAPI } from '../../services/workProfile';

/* Define New action  */
const getList = () => {
  return (dispatch) => {
    return getWorkProfilesAPI()
      .then((data) => {
        if (data) {
          return dispatch({
            type: workProfileType.GET_WORKPROFILE,
            workProfile: data.data,
          });
        }
      })
      .catch((error) => {});
  };
};

const getOneWorkProfile = (id) => {
  return getWorkProfile(id)
    .then((data) => {
      if (data) {
        return data.data;
      }
    })
    .catch((error) => {});
};

const updateWorkProfile = (id, param) => {
  return (dispatch) => {
    return updateWorkProfileAPI(id, param)
      .then((data) => {
        if (data) {
          return data.data;
        }
      })
      .catch((error) => {});
  };
};

export { getList, getOneWorkProfile, updateWorkProfile };
