
import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { Button, Input, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { API } from 'aws-amplify';

function AdvertiserListPageBody (props) {
  const { advertisersList } = props;
  const [ searchText, setSearchText ] = useState('');
  const [ searchedColumn , setSearchedColumn ] = useState(0);

  const searchInput = useRef(null);  
  
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: 8
          }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ?
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          key={dataIndex}
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text?.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: text => <a>{text.toString().padStart(5, '0')}</a>,
    },
    {
      title: 'Name',
      dataIndex: 'first_name',
      ...`${getColumnSearchProps('first_name')} ${getColumnSearchProps('last_name')}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <span>
          <NavLink to={`/advertisers/view/${record.id}`} >View</NavLink>
        </span>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={advertisersList}
      />
    </React.Fragment>
  );
}

export default AdvertiserListPageBody;
