import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Select, Skeleton, Button, notification, Form, Input } from 'antd';
import { getAccounts } from '../../../../../redux/actions/accounts';
import { updateAplicant } from '../../../../../redux/actions/applicants';
import moment from 'moment';
const { Option } = Select;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

function ApplicantSummary (props) {
  const {
    client_id, applicants, getAccountsListHandler, history,
    updateAplicantHandler
  } = props;
  const [ loadded, setLoaded ] = useState(true);
  const [ accountList, setAccountList] = useState([]);
  const [ accountInfo, setAccountInfo ] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    account_id: '',
    createdDatetime: '',
    lastLoginDatetime: ''
  });

  useEffect(()=> {
    if (applicants.length < 1) {
      history.push('/dashboard');
    }
    getAccountsListHandler()
      .then(data => {
        setLoaded(false);
        setAccountList(data.accounts);
        const applicant = applicants.find(item => item.id == client_id);
        setAccountInfo({
          first_name: applicant?.first_name,
          last_name: applicant?.last_name,
          middle_name: applicant?.middle_name,
          account_id: applicant?.account_id,
          createdDatetime: applicant?.createdDatetime,
          lastLoginDatetime: applicant?.lastLoginDatetime
        });
      }).catch(error => {
        openNotificationWithIcon('Error', 'Account Data Error!', 'Can not get the Account data');
        setLoaded(false);
      });
  }, []);

  const handleChange = event => {
    const { id, value } = event.target;
    setAccountInfo(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    setLoaded(true);
    updateAplicantHandler(client_id, accountInfo)
      .then(data => {
        setAccountInfo({
          first_name: data.first_name,
          last_name: data.last_name,
          middle_name: data.middle_name,
          account_id: data.account_id
        });
        setLoaded(false);
      }).catch(erorr => {
        setLoaded(false);
      })
  };

  const handleSelectChange_account = (value) => {
    setAccountInfo(prev => ({
      ...prev,
      account_id: value
    }));
  };

  const accountListSelectOptions = accountList.map(d => <Option key={d.id} >{d.file_by}</Option>);

  return (
    <React.Fragment>
      <Skeleton
        active={loadded}
        loading={loadded}
      >
        <Form
          layout="vertical"
          onSubmit={handleSubmit}
        >
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="First Name">
                    <Input
                      id="first_name"
                      value={accountInfo.first_name}
                      onChange={event => {
                        handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Middle Name(s)">
                    <Input
                      id="middle_name"
                      value={accountInfo.middle_name}
                      onChange={event => {
                        handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Last Name" >
                <Input
                  id="last_name"
                  value={accountInfo.last_name}
                  onChange={event => {
                    handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Created" >
                <Input
                  id="createdDatetime"
                  disabled
                  value={accountInfo.createdDatetime ? moment(accountInfo.createdDatetime).format('YYYY/MM/DD') : ''}
                />
              </Form.Item>
              <Form.Item label="Last Login" >
                <Input
                  id="lastLoginDatetime"
                  disabled
                  value={accountInfo.lastLoginDatetime ? moment(accountInfo.lastLoginDatetime).format('YYYY/MM/DD') : ''}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Account"
                style={{ marginBottom: 0 }}
              >
                <Select
                  id="account_id"
                  showSearch
                  value={
                    typeof accountInfo.account_id === 'number'
                      ? accountInfo.account_id.toString()
                      : accountInfo.account_id
                  }
                  placeholder="Select Account"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  onChange={handleSelectChange_account}
                >
                  {accountListSelectOptions}

                </Select>
              </Form.Item>
              <br />
              <Form.Item label="E-mail">
                <Input
                  id="email"
                  value={accountInfo.email}
                  disabled={true}
                  onChange={event => {
                    handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    applicants: state.applicants.applicants,
  };
};

const mapDispatchToProps = ({
  getAccountsListHandler: getAccounts,
  updateAplicantHandler: updateAplicant
});

const ApplicantSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantSummary);

export default ApplicantSummaryContainer;
