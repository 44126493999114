import { API } from 'aws-amplify';

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const DATA_COUNTRIES = 'DATA_COUNTRIES';
export const DATA_STAFF_STATUS = 'DATA_STAFF_STATUS';

export function onLoginSuccess(user) {
  return async function (dispatch) {
    try {
      const {
        admin_record, user_record, admin_access
      } = await get_current_staff_details(user);
      const countries_list = await get_countries();
      if (admin_record) {
        dispatch(updateCurrentStaffIntoStore(admin_record, user_record, admin_access));
      }
      dispatch(updateCountriesIntoStore(countries_list));
    } catch (e) {
      console.log(e);
    }
  };
}

async function get_current_staff_details(user) {
  if (user.username) {
    return API.get('user-admins', `/get/aws-cognito/${user.username}`)
      .then((response) => {
        return {
          admin_record: response.payload.admin,
          user_record: response.payload.user_admin,
          admin_access: response.payload.admin_access,
        };
      })
      .catch((error) => {
        console.log(error);
      });
  } else if (user.email) {
    return API.get('user-admins', `/get/email/${user.email}`)
      .then((response) => {
        return {
          admin_record: response.payload.admin,
          user_record: response.payload.user_admin,
          admin_access: response.payload.admin_access,
        };
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

async function get_countries() {
  return API.get('countries', '/list')
    .then((response) => {
      return response.payload;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function updateCountriesIntoStore(countries) {
  return {
    type: DATA_COUNTRIES,
    countries: countries,
  };
}

export function updateCurrentStaffIntoStore(admin_record, user_record, admin_access) {
  return {
    type: UPDATE_CURRENT_USER,
    current_user_id: user_record.id,
    current_admin_id: admin_record.id,
    current_admin_record: admin_record,
    current_user_record: user_record,
    admin_access: admin_access,
  };
}
