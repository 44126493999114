import React, {useState} from 'react';
import withStyles from 'react-jss';
import {withRouter} from 'react-router-dom';
import {Tabs} from 'antd';

import WorkProfileSummary from './WorkProfileSummary';

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48
  },
  stepsAction: {
    marginTop: 24,
    float: 'right'
  }
};
const {TabPane} = Tabs;
function callback(key) {
  console.log(key);
}

function WorkProfileViewPageBody(props) {
  const {client_id} = props;
  const [step, setStep] = useState(0);
  return (
    <React.Fragment>
      <Tabs defaultActiveKey='1' onChange={callback}>
        <TabPane tab='Work Profile Summary' key='1'>
          <WorkProfileSummary client_id={client_id} history={props.history} />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
}

export default withRouter(withStyles(styles)(WorkProfileViewPageBody));
