import React, { useState } from 'react';
import withStyles from 'react-jss'
import { withRouter } from 'react-router-dom';
import { Tabs } from 'antd';

import ApplicantSummary from './ApplicantSummary';
import ApplicantContact from './ApplicantContact';
import ApplicantAddress from './ApplicantAddress';
import ApplicantBilling from './ApplicantBilling';

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right'
  },
};
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

function ApplicantViewPageBody(props) {
  const { client_id } = props;
  const [ step, setStep ] = useState(0);
  return (
    <React.Fragment>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Summary" key="1">
          <ApplicantSummary
            client_id={client_id}
            history={props.history}
          />
        </TabPane>
        <TabPane tab="Contact" key="2">
          <ApplicantContact
            client_id={client_id}
            history={props.history}
          />
        </TabPane>
        <TabPane tab="Address" key="3">
          <ApplicantAddress
            client_id={client_id}
            history={props.history}
          />
        </TabPane>
        <TabPane tab="Billing" key="4">
          <ApplicantBilling
            client_id={client_id}
            history={props.history}
          />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
}

export default withRouter(withStyles(styles)(ApplicantViewPageBody))