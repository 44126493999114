
import React from 'react';
import { Row, Col, Form, Typography } from 'antd';
import { connect } from 'react-redux';

const { Title, Text } = Typography;

function Confirm (props) {
  const { newAdvertiser, accounts, countries } = props;
  const {
    first_name,
    middle_name,
    last_name,
    account_id,
    email,
    file_by,
    tel_work,
    tel_home,
    tel_mobile,
    email_secondary,
    street_address_line_1,
    street_address_line_2,
    street_address_suburb,
    street_address_state,
    street_address_postcode,
    street_address_country,
    postal_address_line_1,
    postal_address_line_2,
    postal_address_suburb,
    postal_address_state,
    postal_address_postcode,
    postal_address_country
  } = newAdvertiser;
  const accountID = accounts.accounts.find(item => item.id === account_id);
  const countryName = countries.countries.find( item => item.id === street_address_country);
  const postCountryName = countries.countries.find( item => item.id === postal_address_country );
  return (
    <React.Fragment>
      <Form layout="vertical">
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Col>
              <Title level={4}>Client Details</Title>
              <Row>
                <Col span={8}>
                  <Text>First Name:</Text>
                </Col>
                <Col offset={1}>
                  <Text>{first_name}</Text>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Text>Middle Name:</Text>
                </Col>
                <Col offset={1}>
                  <Text>{middle_name}</Text>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Text>Last Name:</Text>
                </Col>
                <Col offset={1}>
                  <Text>{last_name}</Text>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Text>Account:</Text>
                </Col>
                <Col offset={1}>
                  <Text>{accountID?.file_by}</Text>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Text>E-mail:</Text>
                </Col>
                <Col offset={1}>
                  <Text>{email}</Text>
                </Col>
              </Row>
            </Col>

            <Col gutter={16}>
              <Title level={4}>Contact Details</Title>
              <Row>
                <Col span={8}>
                  <Text>Telephone (home):</Text>
                </Col>
                <Col offset={1}>
                  <Text>{tel_home}</Text>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Text>Telephone (work):</Text>
                </Col>
                <Col offset={1}>
                  <Text>{tel_work}</Text>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Text>Telephone (mobile):</Text>
                </Col>
                <Col offset={1}>
                  <Text>{tel_mobile}</Text>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Text>Primary Email:</Text>
                </Col>
                <Col offset={1}>
                  <Text>{email}</Text>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Text>Secondary Email:</Text>
                </Col>
                <Col offset={1}>
                  <Text>{email_secondary}</Text>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col xs={12}>
            <Col>
              <Title level={4}>Street Address</Title>
              <Row xs={12}>
                <Col span={8}>
                  <Text>Street Address (Line 1):</Text>
                </Col>
                <Text>{street_address_line_1}</Text>
              </Row>

              <Row xs={12}>
                <Col span={8}>
                  <Text>Street Address (Line 2):</Text>
                </Col>
                <Text>{street_address_line_2}</Text>
              </Row>

              <Row xs={12}>
                <Col span={8}>
                  <Text>Suburb:</Text>
                </Col>
                <Text>{street_address_suburb}</Text>
              </Row>

              <Row xs={12}>
                <Col span={8}>
                  <Text>State:</Text>
                </Col>
                <Text>{street_address_state}</Text>
              </Row>

              <Row xs={12}>
                <Col span={8}>
                  <Text>PostCode:</Text>
                </Col>
                <Text>{street_address_postcode}</Text>
              </Row>

              <Row xs={12}>
                <Col span={8}>
                  <Text>Country:</Text>
                </Col>
                <Text>{countryName?.full_name }</Text>
              </Row>
            </Col>
            <Col gutter={16}>
              <Title level={4}>Postal Address:</Title>
              <Row xs={12}>
                <Col span={8}>
                  <Text>Street Address (Line 1):</Text>
                </Col>
                <Text>{postal_address_line_1}</Text>
              </Row>

              <Row xs={12}>
                <Col span={8}>
                  <Text>Street Address (Line 1):</Text>
                </Col>
                <Text>{postal_address_line_1}</Text>
              </Row>

              <Row xs={12}>
                <Col span={8}>
                  <Text>Street Address (Line 2):</Text>
                </Col>
                <Text>{postal_address_line_2}</Text>
              </Row>

              <Row xs={12}>
                <Col span={8}>
                  <Text>Suburb:</Text>
                </Col>
                <Text>{postal_address_suburb}</Text>
              </Row>

              <Row xs={12}>
                <Col span={8}>
                  <Text>State:</Text>
                </Col>
                <Text>{postal_address_state}</Text>
              </Row>

              <Row xs={12}>
                <Col span={8}>
                  <Text>PostCode:</Text>
                </Col>
                <Text>{postal_address_postcode}</Text>
              </Row>

              <Row xs={12}>
                <Col span={8}>
                  <Text>Country:</Text>
                </Col>
                <Text>{postCountryName?.full_name}</Text>
              </Row>
            </Col>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    countries: state.countries,
    accounts: state.accounts
  };
};

const mapDispatchToProps = ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
