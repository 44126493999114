import React, { Component } from 'react';

import JobProfileListPageComponent from './JobProfileListPageComponent';

class JobProfileListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <JobProfileListPageComponent />
    );
  }
}

export default JobProfileListPage;
