import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Card, PageHeader, Button} from 'antd';

import WorkProfileListPageBody from './WorkProfileListPageBody';
import {getList} from '../../../../redux/actions/workProfile';

function WorkProfileListPage(props) {
  const {getListHandler, history, workProfileList} = props;

  useEffect(() => {
    getListHandler()
      .then(data => {
        console.log('getworkProfileList Result', data);
      })
      .catch(error => {
        console.log('getworkProfileList-Error', error);
      });
  }, []);

  const handle_show_all = () => {
    history.push('/advertisers/workplace-profiles');
  };

  return (
    <div
      style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}
    >
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Work Profile Management'
      />
      <Card
        style={{minHeight: 360}}
        title='Current Work Profile List'
        extra={[
          <Button key='2' disabled>
            View Record
          </Button>,
          <Button key='1' onClick={handle_show_all} type='primary'>
            Show All
          </Button>
        ]}
      >
        <div style={{paddingTop: 24}}>
          <WorkProfileListPageBody workProfileList={workProfileList} />
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = state => {
  return {workProfileList: state.workProfile.workProfile};
};

const mapDispatchToProps = {getListHandler: getList};

export default connect(mapStateToProps, mapDispatchToProps)(WorkProfileListPage);
