import React, {
  useState,
  useEffect
} from 'react';

import {
  NavLink
} from 'react-router-dom';
import { Table, Spin } from 'antd';
import { connect } from 'react-redux';

import listHOC from '../../../../../components/common/listHOC';
import { getAllBillingAdv } from '../../../../../redux/actions/billing_advertisers';
import { getAdvertisers } from '../../../../../redux/actions/advertisers';

const BillingAdvertiserListPageComponent = (props) => {

  const { getAllBillingAdvHandler, allBillingAdv, getAdvertisersHandler } = props;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadingAPIs();
  }, []);
  
  const loadingAPIs = async () => {
    setLoading(true);
    await getAllBillingAdvHandler();
    await getAdvertisersHandler();
    setLoading(false);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
    },
    {
      title: 'Credit Balance',
      dataIndex: 'credit_balance',
    },
    {
      title: 'View',
      render: (highlighter, record) => <NavLink to={`/advertisers/view/${record.id}?tab=billing`}>View</NavLink>
    },
  ];

  return <React.Fragment>
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={allBillingAdv}
      />
    </Spin>
  </React.Fragment>;
};

const mapStateToProps = state => {
  return {
    allBillingAdv: state.billingAdv.allBillingAdv
  };
};


const mapDispatchToProps = ({
  getAllBillingAdvHandler: getAllBillingAdv,
  getAdvertisersHandler: getAdvertisers
});

export default connect(mapStateToProps, mapDispatchToProps)(listHOC(BillingAdvertiserListPageComponent));
