import React from 'react';
import { Row, Col, Form, Input, Slider, Switch, Typography, Space } from 'antd';
const { Text } = Typography;
function NewJobWizard02Container(props) {
  const { newJob, setNewJob } = props;
  const handleChange = (event) => {
    const { id, value } = event.target;
    if ((id === 'salary_high' || id === 'salary_low') && value > 10000) {
      return false;
    }
    setNewJob((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleParttimeCheckbox = (checked) => {
    setNewJob((prev) => ({
      ...prev,
      work_option_part_time: checked,
    }));
  };

  const handleFulltimeCheckbox = (checked) => {
    setNewJob((prev) => ({
      ...prev,
      work_option_full_time: checked,
    }));
  };

  const handleCasualCheckbox = (checked) => {
    setNewJob((prev) => ({
      ...prev,
      work_option_casual: checked,
    }));
  };

  const handleRemoteWorkCheckbox = (checked) => {
    setNewJob((prev) => ({
      ...prev,
      remote_work: checked,
    }));
  };

  const handleChangeSalary = (value) => {
    setNewJob((prev) => ({
      ...prev,
      salary_low: value[0],
      salary_high: value[1],
    }));
  };

  return (
    <React.Fragment>
      <Form layout="vertical">
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Form.Item label="Part Time work available">
              <Space size={8}>
                <Switch defaultChecked onChange={handleParttimeCheckbox} checked={newJob.work_option_part_time} />
                <Text>{newJob.work_option_part_time ? 'Yes' : 'No'}</Text>
              </Space>
            </Form.Item>
            <Form.Item label="Full Time work available">
              <Space size={8}>
                <Switch defaultChecked onChange={handleFulltimeCheckbox} checked={newJob.work_option_full_time} />
                <Text>{newJob.work_option_full_time ? 'Yes' : 'No'}</Text>
              </Space>
            </Form.Item>
            <Form.Item label="Casual work available">
              <Space size={8}>
                <Switch defaultChecked onChange={handleCasualCheckbox} checked={newJob.work_option_casual} />
                <Text>{newJob.work_option_casual ? 'Yes' : 'No'}</Text>
              </Space>
            </Form.Item>
            <Form.Item label="Work from home option available">
              <Space size={8}>
                <Switch defaultChecked onChange={handleRemoteWorkCheckbox} checked={newJob.remote_work} />
                <Text>{newJob.remote_work ? 'Yes' : 'No'}</Text>
              </Space>
            </Form.Item>

            <Form.Item label="Salary range" style={{ marginBottom: 0 }}>
              <Slider
                range
                defaultValue={[0, 10000]}
                max={10000}
                value={[newJob.salary_low, newJob.salary_high]}
                onChange={handleChangeSalary}
              />
            </Form.Item>

            <Form.Item label="Salary low" style={{ marginBottom: 0 }}>
              <Input defaultValue={0} id="salary_low" value={newJob.salary_low} onChange={handleChange} />
            </Form.Item>
            <Form.Item label="Salary high" style={{ marginBottom: 0 }}>
              <Input id="salary_high" defaultValue={10000} value={newJob.salary_high} onChange={handleChange} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Job Tasks">
              <Input id="job_tasks" value={newJob.job_tasks} onChange={handleChange} />
            </Form.Item>
            <Form.Item label="Job Responsibilities">
              <Input id="job_responsibilities" value={newJob.job_responsibilities} onChange={handleChange} />
            </Form.Item>

            <Form.Item label="Job Benefits">
              <Input id="job_benefits" value={newJob.job_benefits} onChange={handleChange} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default NewJobWizard02Container;
