import { jobsType } from '../type';
import { getJobsAPI, getJobAPI, updateJobAPI, createJobAPI, getJobsStatusList } from '../../services/jobs';

const getList = () => {
  return (dispatch) => {
    return getJobsAPI()
      .then((data) => {
        return dispatch({
          type: jobsType.GET_JOBS,
          jobs: data.data,
        });
      })
      .catch((error) => {});
  };
};

const getStatusList = () => {
  return (dispatch) => {
    return getJobsStatusList()
      .then((data) => {
        return dispatch({
          type: jobsType.GET_JOB_STATUS_LIST,
          data: data.data,
        });
      })
      .catch((error) => {});
  };
};

const getJob = (id) => {
  return getJobAPI(id)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});
};

const updateJob = (id, param) => {
  return updateJobAPI(id, param)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});
};

const createJob = (param) => {
  return createJobAPI(param)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});
};

export { getList, getJob, updateJob, createJob, getStatusList };
