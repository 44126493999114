import { Button, notification, Steps, Spin } from 'antd';
import React, { useState } from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createAdertiser } from '../../../../../redux/actions/advertisers';
import ClientDetails from './ClientDetails';
import ContactDetails from './ContactDetails';
import Confirm from './Confirm';

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right'
  },
};

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const { Step } = Steps;

function AdvertiserNewPageBody(props) {
  const { classes, history, createAdertiserHandler } = props;
  const [ loading, setLoading ] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepStatusError, setStepStatusError] = useState(false);
  const [newAdvertiser, setNewAdvertiser] = useState({
    "first_name": "",
    "middle_name": "",
    "last_name": "",
    "account_id": 1,
    "email": "",
    "file_by": "",
    "tel_work": "",
    "tel_home": "",
    "tel_mobile": "",
    "email_secondary": "",
    "street_address_line_1": "",
    "street_address_line_2": "",
    "street_address_suburb": "",
    "street_address_state": "",
    "street_address_postcode": "",
    "street_address_country": 9,
    "postal_address_line_1": "",
    "postal_address_line_2": "",
    "postal_address_suburb": "",
    "postal_address_state": "",
    "postal_address_postcode": "",
    "postal_address_country": 9
  })

  const handleSubmit = async (event) => {
    setLoading(true);
    await createAdertiserHandler(newAdvertiser);
    setLoading(false);
    history.push('/advertisers/list');
  }

  const handleNextStep = () => {
    const validation = (newAdvertiser.first_name && newAdvertiser.last_name && newAdvertiser.account_id && newAdvertiser.email);
    switch(currentStep) {
      case 0:
        validation ? setCurrentStep(prev => prev + 1) : setStepStatusError(false);
        break;
      case 1:
        validation ? setCurrentStep(prev => prev + 1) : setStepStatusError(false);
        break;
      case 2:
        break;
      default:
        break;
    }
  };

  const handlePreStep = () => {
    setCurrentStep(prev => prev - 1)
  }

  const steps = [
    {
      title: 'Client Details',
      content: <ClientDetails setNewAdvertiser={setNewAdvertiser} newAdvertiser={newAdvertiser} />,
    },
    {
      title: 'Contact Details',
      content: <ContactDetails setNewAdvertiser={setNewAdvertiser} newAdvertiser={newAdvertiser} />,
    },
    {
      title: 'Confirm',
      content: <Confirm newAdvertiser={newAdvertiser} />,
    },
  ];

  return (
    <React.Fragment>
      <Spin spinning={loading}>
      <Steps
        current={currentStep}
        status={stepStatusError ? 'error' : ''}
      >
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className={classes.stepsContent}>
        {steps[currentStep].content}
      </div>
      <div className={classes.stepsAction}>
        {currentStep > 0 && (
          <Button style={{ marginLeft: 8 }} onClick={handlePreStep}>
            Previous
          </Button>
        )}
        {currentStep < steps.length - 1 && (
          <Button type="primary" onClick={handleNextStep}>
            Next
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <Button type="primary" onClick={handleSubmit}>
            Done
          </Button>
        )}
      </div>
      </Spin>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = ({
  createAdertiserHandler: createAdertiser
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(AdvertiserNewPageBody)));