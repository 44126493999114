import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

/* Define the Accounts Endpoint */
const getAllBillingAdvAPI = () => {
  const path = '/admin/v1/billing-advertisers';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const getBillingAdvAPI = (id) => {
  const path = `/admin/v1/billing-advertisers/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const createCustomerAdvAPI = (id) => {
  const path = `/admin/v1/billing-advertisers/create-customer/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const productBalanceListAPI = (id) => {
  const path = `/admin/v1/billing-advertisers/advertiser-product-balances/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const accountHistoryListAPI = (id) => {
  const path = `/admin/v1/billing-advertisers/account-history/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

export { getAllBillingAdvAPI, getBillingAdvAPI, createCustomerAdvAPI, productBalanceListAPI, accountHistoryListAPI };
