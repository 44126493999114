import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Card, PageHeader, Space, Typography, Input, Spin, Row, Col, Button, notification, InputNumber} from 'antd';
import {connect} from 'react-redux';
import {getAdertProductAPI, updateAdertProductAPI} from '../../../../services/advert_products';
import {getAdertProductList} from '../../../../redux/actions/advert_products';

const {Text} = Typography;
const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  });
};
function AdvertProjectViewPage() {
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [advertProduct, setAdvertProduct] = useState({});
  const [updatedData, setUpdatedData] = useState({});

  useEffect(() => {
    if (id) {
      setLoading(true);
      getAdertProductAPI(id)
        .then(data => {
          setAdvertProduct(data.data);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    }
  }, []);
  console.log('venus----advertProduct', advertProduct, id);

  const onChangeName = e => {
    const {value} = e.target;
    setUpdatedData(prev => ({
      ...prev,
      advert_name: value,
      retail_price: prev.retail_price || advertProduct.retail_price
    }));
  };
  const onChangePrice = value => {
    setUpdatedData(prev => ({
      ...prev,
      advert_name: prev.advert_name || advertProduct.advert_name,
      retail_price: value
    }));
  };
  const handleChangeAdvertInfo = () => {
    updateAdertProductAPI(advertProduct.id, updatedData).then(data => {
      openNotificationWithIcon('success', 'Applicant billing info', 'Success to Save.');
      console.log('changeBillingInfo success', data);
    }).catch(err => {
      openNotificationWithIcon('success', 'Applicant billing info', 'Failed to Save.')
      console.log('changeBillingInfo failed', err);
    });
  }

  return (
    <div
      style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}
    >
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Advertisement Product View'
      />
      <Spin spinning={loading}>
        {Object.keys(advertProduct).length > 0 ? (
          <Row>
            <Col span={24}>
              <Card style={{minHeight: 360}} title='View Advertisement Product'>
                <div style={{paddingTop: 24}}>
                  <Space size={20}>
                    <Space size={15} direction='vertical'>
                      <Text>Name:</Text>
                      <Text>Price:</Text>
                    </Space>
                    <Space size={15} direction='vertical'>
                      <Input
                        onChange={onChangeName}
                        placeholder='Input a Name'
                        defaultValue={advertProduct.advert_name}
                      />
                      <InputNumber
                        onChange={onChangePrice}
                        placeholder='Input a Price'
                        defaultValue={advertProduct.retail_price}
                      />
                    </Space>
                  </Space>
                </div>
              </Card>
            </Col>
            <Col span={24} style={{justifyContent: 'flex-end', display: 'flex', marginTop: 100}}>
              <Button type='primary' disabled={Object.keys(updatedData).length === 0} onClick={handleChangeAdvertInfo}>
                Save
              </Button>
            </Col>
          </Row>
        ) : (
          <div style={{width: '100%', height: '100%'}} />
        )}
      </Spin>
    </div>
  );
}

const mapDispatchToProps = {
  getAdertProductListHandler: getAdertProductList
};

export default connect(null, mapDispatchToProps)(AdvertProjectViewPage);
