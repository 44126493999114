import { authActionType, userProfileType } from '../type';

const initialState = {
  loggedIn: false,
  currentUser: null,
  isAuthenticated: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case authActionType.SIGN_UP:
      return {
        ...state,
        currentUser: action.user,
        loggedIn: true,
        isAuthenticated: true,
      };

    case authActionType.SIGN_IN:
      return {
        ...state,
        currentUser: action.user,
        loggedIn: true,
        isAuthenticated: true,
      };

    case authActionType.SIGN_OUT:
      return {
        loggedIn: false,
        isAuthenticated: false,
      };
    case authActionType.AUTH_VALID:
      return {
        isAuthenticated: action.isAuthenticated,
        currentUser: action.user,
      };

    case userProfileType.GET_USER_PROFILE:
      return {
        ...state,
        currentUser: action.user,
      };

    case userProfileType.UPDATE_USER_PROFILE:
      return {
        ...state,
        currentUser: action.user,
      };

    default:
      return state;
  }
}
