import React, { useEffect, useRef, useState } from 'react';

import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const listHOC = (Component) => ({
  current_account_id, fetchCb, ...props
}) => {
  const [state, setState] = useState({
    searchedColumn: '',
    searchWords: [], // For Highlighter
  });
  const searchInput = useRef();
  const prevAccountId = useRef();
  useEffect(() => {
    if (current_account_id && prevAccountId.current !== current_account_id) {
      fetchCb(current_account_id);
      prevAccountId.current = current_account_id;
    }
  }, [current_account_id, state, setState, fetchCb]);
  const { searchWords, searchedColumn } = state;
  /**
   * @param {Array} selectedKeys
   * @param {Function} confirm
   * @param {string} dataIndex
   */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setState({
      searchWords: selectedKeys,
      searchedColumn: dataIndex,
    });
    confirm();
  };
  /**
   * @param {Function} clearFilters
   */
  const handleReset = (clearFilters) => {
    clearFilters();
    setState({
      ...state,
      searchWords: [],
    });
  };

  const getColumnSearchProps = (
    dataIndex,
    placeholder = dataIndex,
    render = false,
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search by ${placeholder}`}
          value={selectedKeys[0]}
          onChange={({ target: { value } }) =>
            setSelectedKeys(value ? [value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: 8,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered && '#1890ff' }} />
    ),
    onFilter: (value, record) =>
      String(record[dataIndex]).toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: render
      ? render
      : (text) => {
        return searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={searchWords}
            autoEscape
            textToHighlight={text?.toString()}
          />
        ) : (
          text
        );
      },
  });
  return <Component {...props} getColumnSearchProps={getColumnSearchProps} />;
};
export default listHOC;
