import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { PageHeader } from 'antd';
import { FormattedMessage } from 'react-intl';

import LoginPageComponent from './LoginPageComponent';
import CssConfig from '../../../components/common/CssConfig';

function LoginPage(props) {
  return (
    <LoginPageContainer>
      <LoginPageHeader title={<FormattedMessage id="login_page.main_title" />} />
      <LoginPageComponent />
    </LoginPageContainer>
  );
}

const LoginPageContainer = styled.div`
   {
    padding: 24px;
    background: ${CssConfig.loginScreenBG};
    min-height: 360px;
  }
`;

const LoginPageHeader = styled(PageHeader)`
   {
    padding-left: 0;
    border-bottom: 1px solid ${CssConfig.borderColor};
    margin-bottom: 16px;
  }
`;

export default withRouter(LoginPage);
