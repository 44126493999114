import React from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

function JobAdvertisement(props) {
  const {
    currentJob, setCurrentJob, handleSubmit
  } = props;

  const handleChange = (event) => {
    const { id, value } = event.target;
    setCurrentJob((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  return (
    <React.Fragment>
      <Form layout="vertical">
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Form.Item label="Short Description">
              <Input
                id="short_description"
                value={currentJob.short_description}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Advertisement summary">
              <TextArea
                id="advertisement_summary"
                value={currentJob.advertisement_summary}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Advertisement text">
              <TextArea
                id="advertisement_text"
                value={currentJob.advertisement_text}
                onChange={handleChange}
                rows={20}
              />
            </Form.Item>
          </Col>
          <Col xs={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" onClick={handleSubmit}>
              Save Record
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default JobAdvertisement;
