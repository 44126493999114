import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Select, Skeleton, Button, notification, Form, Input} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import ReactPlayer from 'react-player';

import {getAccounts} from '../../../../../redux/actions/accounts';
import {getCountries} from '../../../../../redux/actions/countries';
import {updateWorkProfile} from '../../../../../redux/actions/workProfile';
import CountrySeleteComponent from '../../../../../components/common/CountrySeleteComponent';

// const videoFileTyps = '.webm, .mpeg, .mp2, .mpg, .mpv .mkv, .ogg, .mp4, .m4p, .m4v, .avi, .wmv, .mov, .qt, .flv, .swf';

// import moment from 'moment';
const {Option} = Select;

// const dateFormat = 'YYYY/MM/DD HH:mm:ss';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  });
};

function WorkProfileSummary(props) {
  const {
    client_id,
    workProfile,
    getAccountsListHandler,
    history,
    updateWorkProfileHandler,
    countries,
    getCountriesHandler
  } = props;
  const [loadded, setLoaded] = useState(true);
  // const [accountList, setAccountList] = useState([]);
  const [accountInfo, setAccountInfo] = useState({
    workplace_name: '',
    createdAt: '',
    updatedAt: '',
    region: '',
    country_id: '',
    employee_count: '',
    short_description: '',
    long_description: '',
    pitch_video_file_id: ''
  });
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    if (workProfile.length < 1) {
      history.push('/dashboard');
    }
    getAccountsListHandler()
      .then(data => {
        setLoaded(false);
        // setAccountList(data.accounts);
        const advWorkProfile = workProfile.find(item => item.id == client_id);
        setAccountInfo({
          workplace_name: advWorkProfile?.workplace_name,
          createdAt: advWorkProfile?.createdAt,
          updatedAt: advWorkProfile?.updatedAt,
          region: advWorkProfile?.region,
          country_id: advWorkProfile?.country_id,
          employee_count: advWorkProfile?.employee_count,
          short_description: advWorkProfile?.short_description,
          long_description: advWorkProfile?.long_description,
          pitch_video_file_id: advWorkProfile?.pitch_video_file_id
        });
      })
      .catch(error => {
        openNotificationWithIcon('Error', 'Account Data Error!', 'Can not get the Account data');
        setLoaded(false);
      });

    if (accountInfo && accountInfo.pitch_video_file_id) {
      setVideoList([
        {
          ...JSON.parse(accountInfo.pitch_video_file_id),
          uid: 'advertiser-vu-1'
        }
      ]);
    }

    getCountriesHandler();

    // const data_countries_list = getCountriesHandler().then(response => {
    //   // console.log(response);
    //   // return response.countries;
    //   setAccountInfo({data_countries_list: response.countries})
    //   setAccountInfo({loadded: false})
    // })
    //   .catch(error => {
    //     console.log(error);
    //     setAccountInfo({data_countries_list: []})
    //   });
  }, []);

  // const handleUpdatedAtChange = (date, dateString) => {
  //   console.log(date,dateString)
  //   if (date && dateString)
  //     setAccountInfo(prev => ({
  //         ...prev,
  //         updatedAt: dateString
  //       }));
  // };

  const handleChange = event => {
    const {id, value} = event.target;
    setAccountInfo(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const handleRemoveVideo = () => {
    setAccountInfo(prev => ({
      ...prev,
      pitch_video_file_id: null
    }));
    setVideoList([]);
  };

  const handleSelectChange_country = value => {
    setAccountInfo(prev => ({
      ...prev,
      country_id: value
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    setLoaded(true);
    updateWorkProfileHandler(client_id, accountInfo)
      .then(data => {
        setAccountInfo({
          workplace_name: data.workplace_name,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          region: data.region,
          country_id: data.country_id,
          employee_count: data.employee_count,
          short_description: data.short_description,
          long_description: data.long_description,
          pitch_video_file_id: data.pitch_video_file_id
        });
        setLoaded(false);
      })
      .catch(erorr => {
        setLoaded(false);
      });
  };
  const videoUrl = accountInfo.pitch_video_file_id ? JSON.parse(accountInfo.pitch_video_file_id).path : null;

  return (
    <React.Fragment>
      <Skeleton active={loadded} loading={loadded}>
        <Form layout='vertical' onSubmit={handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col>
              <Row gutter={[12, 0]}>
                <Col>
                  <Form.Item label='Work Place Name'>
                    <Input
                      id='workplace_name'
                      value={accountInfo.workplace_name}
                      onChange={event => {
                        handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label='Created At'>
                <Input
                  id='createdAt'
                  value={accountInfo.createdAt}
                  disabled
                  onChange={event => {
                    handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label='Updated At'>
                <Input
                  id='updatedAt'
                  value={accountInfo.updatedAt}
                  disabled
                  onChange={event => {
                    handleChange(event);
                  }}
                />
                {/*
                <DatePicker
                  value={moment(accountInfo.updatedAt, dateFormat)}
                  format={dateFormat}
                  showTime
                  onChange={handleUpdatedAtChange}
                />*/}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='Region'>
                <Input
                  id='region'
                  value={accountInfo.region}
                  onChange={event => {
                    handleChange(event);
                  }}
                />
              </Form.Item>
              {/* temp fix for #68
              <Form.Item label='Country'>
                <Input
                  id='country_id'
                  value={countries.countries.find(item => item.id === Number(`${accountInfo.country_id}`))?.full_name}
                  onChange={event => {
                    handleChange(event);
                  }}
                />
              </Form.Item>
               */}
              <Form.Item label='Country'>
                <CountrySeleteComponent
                  id='country_id'
                  value={
                    typeof accountInfo.country_id === 'number'
                      ? accountInfo.country_id.toString()
                      : accountInfo.country_id
                  }
                  handleChange={handleSelectChange_country}
                />
              </Form.Item>
              <Form.Item label='Employee Count'>
                <Input
                  id='employee_count'
                  value={accountInfo.employee_count}
                  onChange={event => {
                    handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='Short Description'>
                <Input
                  id='short_description'
                  value={accountInfo.short_description}
                  onChange={event => {
                    handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label='Long Description'>
                <TextArea
                  id='long_description'
                  value={accountInfo.long_description}
                  rows={4}
                  onChange={event => {
                    handleChange(event);
                  }}
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label='Video'>
            {/* disabled debugging tool
              <TextArea
                id='pitch_video_file_id'
                value={accountInfo.pitch_video_file_id}
                rows={4}
                disabled
              >
              </TextArea>
            */}
            <React.Fragment>
              {videoUrl ? (
                <Col>
                  <ReactPlayer
                    url={videoUrl}
                    controls={true}
                    width='100%'
                    height='100%'
                    style={{maxWidth: 500}}
                    playing={true}
                  />
                  <Button type='primary' danger style={{marginTop: 10}} onClick={handleRemoveVideo}>
                    Remove
                  </Button>
                </Col>
              ) : (
                ''
              )}
            </React.Fragment>
          </Form.Item>

          <Row gutter={[96, 24]}>
            <Col xs={24} style={{textAlign: 'right'}}>
              <Button type='primary' htmlType='submit' onClick={handleSubmit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  // console.log(state);
  return {
    app_state: state.app_state,
    countries: state.countries.countries,
    language: state.language,
    workProfile: state.workProfile.workProfile
  };
};

const mapDispatchToProps = {
  getAccountsListHandler: getAccounts,
  updateWorkProfileHandler: updateWorkProfile,
  getCountriesHandler: getCountries
};

const WorkProfileSummaryContainer = connect(mapStateToProps, mapDispatchToProps)(WorkProfileSummary);

export default WorkProfileSummaryContainer;
