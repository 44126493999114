import React from 'react';

import { Row, Col, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

function NewJobWizard03Container(props) {
  const { newJob, setNewJob } = props;

  const handleChange = (event) => {
    const { id, value } = event.target;
    setNewJob((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  return (
    <React.Fragment>
      <Form layout="vertical">
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Form.Item label="Short Description">
              <Input id="short_description" disabled value={newJob.short_description} onChange={handleChange} />
            </Form.Item>
            <Form.Item label="Advertisement summary">
              <TextArea id="advertisement_summary" value={newJob.advertisement_summary} onChange={handleChange} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Advertisement text">
              <TextArea id="advertisement_text" value={newJob.advertisement_text} onChange={handleChange} rows={20} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default NewJobWizard03Container;
