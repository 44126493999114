import PagesContainer from '../layouts/pages';
import DashboardContainer from '../layouts/dashboard';
import ConfirmEmailAddress from '../views/pages/ConfrimEmailAddressPage';
import ResetPassword from '../views/pages/ResetPassword';

const authRoutes = [
  {
    path: '/',
    name: 'Home',
    component: DashboardContainer,
  },
];

const unAuthRoutes = [
  {
    path: '/pages',
    name: 'Pages',
    component: PagesContainer,
  },
];

const appliedRoutes = [
  {
    path: '/confirm-email',
    name: 'ConfirmEmailAddress Page',
    component: ConfirmEmailAddress,
  },
  {
    path: '/reset-password',
    name: 'Reset Password Page',
    component: ResetPassword,
  },
];

export { authRoutes, unAuthRoutes, appliedRoutes };
