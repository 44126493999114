import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {Space, Typography, Spin, PageHeader, Card, notification, Button, Select, Input, Row, Col} from 'antd';
import {useParams} from 'react-router-dom';
import {getBillingAppAPI, updateBillingInfoAPI} from '../../../../services/billing_applicants';

const {Text} = Typography;
const {Option} = Select;
const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  });
};
const ApplicantSubscriptionView = () => {
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [subAppScriptionData, setAppSubscriptionData] = useState({});
  const [updatedData, setUpdatedData] = useState({});
  const status = [
    {
      id: 1,
      value: 'Pending'
    },
    {
      id: 2,
      value: 'Active'
    },
    {
      id: 3,
      value: 'Cancelling'
    },
    {
      id: 4,
      value: 'Cancelled'
    }
  ];

  const handleChangeStatus = e => {
    setUpdatedData(prev => ({
      ...prev,
      status_id: e,
      stripe_id: updatedData.stripe_id || subAppScriptionData.stripe_id
    }));
  };

  const handleCreateStripID = e => {
    const {value} = e.target;
    setUpdatedData(prev => ({
      ...prev,
      stripe_id: value,
      status_id: updatedData.status_id || subAppScriptionData.status_id
    }));
  };

  const handleChangeBillingInfo = async () => {
    updateBillingInfoAPI(subAppScriptionData.id, updatedData).then(data => {
      openNotificationWithIcon('success', 'Applicant billing info', 'Success to Save.');
      console.log('changeBillingInfo success', data);
    }).catch(err => {
      openNotificationWithIcon('success', 'Applicant billing info', 'Failed to Save.')
      console.log('changeBillingInfo failed', err);
    });
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getBillingAppAPI(id)
        .then(data => {
          setAppSubscriptionData(data.data);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <div
      style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}
    >
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Subscription'
      />
      <Card style={{minHeight: 360}}>
        <React.Fragment>
          <Spin spinning={loading}>
            <Row>
              <Col span={24}>
                {Object.keys(subAppScriptionData).length > 0 ? (
                  <Space direction='vertical' size={10}>
                    <Space direction='horizontal' size={20}>
                      <Text style={{fontWeight: 'bold'}}>Name:</Text>
                      <Text>{`${subAppScriptionData?.applicant?.first_name} ${subAppScriptionData?.applicant?.last_name}`}</Text>
                    </Space>

                    <Space direction='horizontal'>
                      <Text style={{fontWeight: 'bold'}}>Email:</Text>
                      <Text>{subAppScriptionData?.applicant?.email}</Text>
                    </Space>

                    <Space direction='horizontal'>
                      <Text style={{fontWeight: 'bold'}}>Subscription Stripe ID:</Text>
                      <Input defaultValue={subAppScriptionData?.stripe_id} onChange={handleCreateStripID} />
                    </Space>

                    <Space direction='horizontal'>
                      <Text style={{fontWeight: 'bold'}}>subscription status:</Text>
                      <Select
                        defaultValue={subAppScriptionData?.status_id}
                        style={{width: 120}}
                        onChange={handleChangeStatus}
                      >
                        {status.map(item => (
                          <Option value={item.id} key={item.id}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    </Space>
                  </Space>
                ) : (
                  <Space style={{width: '100%', height: '100%'}} />
                )}
              </Col>  
              <Col span={24} style={{ justifyContent: 'flex-end', display: 'flex', marginTop: 100}}>
                <Button type='primary' disabled={Object.keys(updatedData).length === 0} onClick={handleChangeBillingInfo}>
                  Save
                </Button>
              </Col>
            </Row>
          </Spin>
        </React.Fragment>
      </Card>
    </div>
  );
};

export default ApplicantSubscriptionView;
