import { subscriptionType } from '../type';
import { getSubscriptionsAPI } from '../../services/subscripitions';

const getSubscriptions = () => {
  return (dispatch) => {
    return getSubscriptionsAPI()
      .then((data) => {
        return dispatch({
          type: subscriptionType.GET_SUBSCRIPTIONS_LIST,
          subscriptions: data.data,
        });
      })
      .catch((error) => {});
  };
};

export { getSubscriptions };
