import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Card, PageHeader, Button} from 'antd';
import WorkProfileViewPageBody from './body';

function WorkProfileViewPage() {
  const history = useHistory();
  const params = useParams();
  const handle_show_all = () => {
    history.push('/advertisers/workplace-profiles/');
  };

  return (
    <div
      style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}
    >
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Work Profile Management'
      />
      <Card
        style={{minHeight: 360}}
        title='View and update your Work Profile Details'
        extra={[
          <Button key='2' disabled>
            View Record
          </Button>,
          <Button key='1' onClick={handle_show_all} type='primary'>
            Show All
          </Button>
        ]}
      >
        <div style={{paddingTop: 24}}>
          <WorkProfileViewPageBody client_id={params.client_id} />
        </div>
      </Card>
    </div>
  );
}

export default WorkProfileViewPage;
