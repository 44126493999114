/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { NavLink, Switch, Redirect, useLocation } from 'react-router-dom';
import { Row, Col, Layout, Menu, Icon, Modal, Space } from 'antd';
import {
  DashboardOutlined,
  BankOutlined,
  TeamOutlined,
  ContainerOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import dashboardRoutes from '../../routes/dashboard.jsx';
import AuthenticatedRoute from '../../components/routes/AuthenticatedRoute';
import DashboardHeaderContainer from './DashboardHeader.jsx';
import '../../style/sidebar.scss';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const navBarRoute = {
  '/dashboard': {
    sub: 'dashboard',
    item: 'dashboard',
  },
  '/advertisers/list': {
    sub: 'advertisers_list',
    item: 'advertisers_sub',
  },
  '/advertisers/new': {
    sub: 'advertisers_new',
    item: 'advertisers_sub',
  },
  advertisers_workProfile: {
    sub: '/advertisers/workplace-profiles',
    item: 'advertisers_sub',
  },
  '/jobs/list': {
    sub: 'jobs_list',
    item: 'jobs_sub',
  },
  '/jobs/new': {
    sub: 'jobs_new',
    item: 'jobs_sub',
  },
  '/billing/applicants/list': {
    sub: 'billing_applicants',
    item: 'billing_sub',
  },
  '/billing/advertisers/list': {
    sub: 'billing_advertisers',
    item: 'billing_sub',
  },
  '/applicants/list': {
    sub: 'applicants_list',
    item: 'applicants_sub',
  },
  '/applicants/new': {
    sub: 'applicants_new',
    item: 'applicants_sub',
  },
  '/account/view/1': {
    sub: 'current_account_view',
    item: 'accounts_sub',
  },
  '/user-profile': {
    sub: 'user_profile_sub',
    item: 'settings_sub',
  },
};

function Dashboard(props) {
  const { reload_user, current_user, isAuthenticated, userHasAuthenticated, handleLogout } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [isWelcome, _] = useState(localStorage.getItem('STATUS_REQUIRE_WELCOME'));

  useEffect(() => {
    if (isWelcome === 'true') {
      const modal = Modal.success({
        title: 'Welcome to PlatinumJobSeeker',
        content: 'This modal will be destroyed after 10 seconds.',
      });

      setTimeout(() => {
        modal.destroy();
        localStorage.setItem('STATUS_REQUIRE_WELCOME', 'false');
      }, 10 * 1000);
    }
  }, [isWelcome]);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const onClick = () => {
    window.location.assign('/datePickerSample');
  };

  const location = useLocation();

  const switchRoutes = () => {
    return (
      <Switch>
        {dashboardRoutes.map((prop, key) => {
          if (prop.redirect) {
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          }
          if (prop.collapse) {
            return prop.views.map((prop, key) => {
              return (
                <AuthenticatedRoute
                  path={prop.path}
                  component={prop.component}
                  key={key}
                  props={{
                    isAuthenticated: isAuthenticated,
                    current_user: current_user,
                    reload_user: reload_user,
                  }}
                />
              );
            });
          }
          return (
            <AuthenticatedRoute
              path={prop.path}
              component={prop.component}
              key={key}
              props={{
                isAuthenticated: isAuthenticated,
                current_user: current_user,
                reload_user: reload_user,
              }}
            />
          );
        })}
      </Switch>
    );
  };

  const generate_sidebar = () => {
    return (
      <Menu
        theme="light"
        defaultOpenKeys={[navBarRoute[location.pathname]?.item]}
        defaultSelectedKeys={[navBarRoute[location.pathname]?.sub]}
        mode="inline"
        style={{ height: '100%' }}
      >
        <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
          <NavLink to="/dashboard">
            <Icon type="user" />
            <span>Dashboard</span>
          </NavLink>
        </Menu.Item>
        <SubMenu
          icon={<BankOutlined />}
          key="advertisers_sub"
          title={
            <span>
              <Icon type="team" />
              <span>Advertisers</span>
            </span>
          }
        >
          <Menu.Item key="advertisers_list">
            <NavLink to="/advertisers/list">Advertiser List</NavLink>
          </Menu.Item>
          <Menu.Item key="advertisers_new">
            <NavLink to="/advertisers/new">New Advertiser</NavLink>
          </Menu.Item>
          <Menu.Item key="advertisers_workProfile">
            <NavLink to="/advertisers/workplace-profiles">Workplace Profiles</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="jobs_sub"
          icon={<ContainerOutlined />}
          title={
            <span>
              <Icon type="team" />
              <span>Jobs</span>
            </span>
          }
        >
          <Menu.Item key="jobs_list">
            <NavLink to="/jobs/list">Job List</NavLink>
          </Menu.Item>
          <Menu.Item key="jobs_new">
            <NavLink to="/jobs/new">New Job</NavLink>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="applicants_sub"
          icon={<TeamOutlined />}
          title={
            <span>
              <Icon type="team" />
              <span>Applicants</span>
            </span>
          }
        >
          <Menu.Item key="applicants_list">
            <NavLink to="/applicants/list">Applicant List</NavLink>
          </Menu.Item>
          <Menu.Item key="applicants_new">
            <NavLink to="/applicants/new">New Applicant</NavLink>
          </Menu.Item>
          <Menu.Item key="applicants_jobProfiles">
            <NavLink to="/applicants/job-profiles">Job Profiles</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="billing_sub"
          icon={<BankOutlined />}
          title={
            <span>
              <Icon type="team" />
              <span>Billing</span>
            </span>
          }
        >
          <Menu.Item key="billing_advertisers">
            <NavLink to="/billing/advertisers/list">Advertisers</NavLink>
          </Menu.Item>
          <Menu.Item key="billing_applicants">
            <NavLink to="/billing/applicants/list">Applicants</NavLink>
          </Menu.Item>
          <Menu.Item key="advert_product">
            <NavLink to="/billing/advert-products/list">Advert Products</NavLink>
          </Menu.Item>
          <Menu.Item key="billing_subscriptions">
            <NavLink to="/billing/subscriptions/list">Subscriptions</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="accounts_sub"
          icon={<UsergroupAddOutlined />}
          title={
            <span>
              <Icon type="team" />
              <span>Accounts</span>
            </span>
          }
        >
          <Menu.Item key="current_account_view">
            <NavLink to="/account/view/1">Current Account</NavLink>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="legal" icon={<CheckCircleOutlined />}>
          <NavLink to="/legal">
            <Icon type="user" />
            <span>Legal</span>
          </NavLink>
        </Menu.Item>
        <SubMenu
          key="settings_sub"
          icon={<SettingOutlined />}
          title={
            <span>
              <Icon type="team" />
              <span>Settings</span>
            </span>
          }
        >
          <Menu.Item key="user_profile_sub">
            <NavLink to="/user-profile?tab=summary">
              <Icon type="user" />
              <span>User Profile</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Space direction="vertical" style={{ backgroundColor: 'white' }}>
        <div className="logo" />
        <img
          src={require(!collapsed
            ? '../../assets/img/brand/PlatinumJobSeeker_300px.png'
            : '../../assets/img/brand/TickWithBorder_50px.png')}
          alt=""
          target="_blank"
          style={{
            paddingTop: 16,
            marginBottom: 16,
            maxHeight: 64,
            maxWidth: 200,
            marginLeft: collapsed ? 15 : 0,
            width: collapsed ? 50 : '100%',
          }}
        />
        <Sider
          theme="light"
          style={{ minHeight: '100vh', backgroundColor: 'white' }}
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          {generate_sidebar()}
        </Sider>
      </Space>
      <Layout>
        <Header
          style={{
            background: '#fff',
            padding: 0,
          }}
        >
          <DashboardHeaderContainer
            isAuthenticated={isAuthenticated}
            userHasAuthenticated={userHasAuthenticated}
            handleLogout={handleLogout}
          />
        </Header>
        <Layout>
          <Content style={{ margin: '16px' }}>
            <div
              style={{
                padding: 24,
                background: '#fff',
                minHeight: 360,
              }}
            >
              <div>{switchRoutes()}</div>
            </div>
          </Content>
        </Layout>
        <Footer style={{ textAlign: 'left' }}>
          <img alt="brand" src={require('../../assets/img/brand/TickWithBorder_20px.png')} />
          <strong>
            Platinumjobseeker<sup>TM</sup>
          </strong>{' '}
          Admin Portal © 2020-2021
          <br />
          <small>Version 0.7.0</small>
          <br/>
          <Row>
            <Col xs={24} style={{ textAlign: 'right' }}>
              <small>
              Created by{' '}
                <a href="https://deliostech.com/" target="_blank">
                Delios Technology Inc
                </a>
              </small>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
