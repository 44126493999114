import LoginPage from '../views/pages/login';
import RegisterPage from '../views/pages/RegisterPage';

const pagesRoutes = [
  {
    path: '/pages/register',
    name: 'Register Page',
    short: 'Register',
    mini: 'RP',
    component: RegisterPage,
  },
  {
    path: '/pages/login',
    name: 'Login Page',
    short: 'Login',
    mini: 'LP',
    component: LoginPage,
  },
];

export default pagesRoutes;
