/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Switch, Redirect, NavLink } from 'react-router-dom';
import { Row, Col, Layout, Menu, Icon, Space } from 'antd';

import pagesRoutes from '../../routes/pages.jsx';
import AppliedRoute from '../../components/routes/AppliedRoute';
import PagesHeaderContainer from './PagesHeader.jsx';

const {
  Header, Content, Footer, Sider
} = Layout;

class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      isAuthenticated: false,
    };
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  generate_sidebar = () => {
    return (
      <Menu theme="light" defaultSelectedKeys={['1']} mode="inline" style={{ height: '100%' }}>
        <Menu.Item key="1">
          <NavLink to="/">
            <Icon type="user" />
            <span>Login</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/pages/register">
            <Icon type="user" />
            <span>Sign Up</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    return (
      <Layout theme="light" style={{ minHeight: '100vh' }}>
        <Space direction="vertical" style={{ backgroundColor: 'white' }}>
          <div className="logo" />
          <img
            src={require(!this.state.collapsed
              ? '../../assets/img/brand/PlatinumJobSeeker_300px.png'
              : '../../assets/img/brand/TickWithBorder_50px.png')}
            alt=""
            target="_blank"
            style={{
              paddingTop: 16,
              marginBottom: 16,
              maxHeight: 64,
              maxWidth: 200,
              marginLeft: this.state.collapsed ? 15 : 0,
              width: this.state.collapsed ? 50 : '100%',
            }}
          />
          <Sider
            style={{
              minHeight: '100vh',
              backgroundColor: 'white'
            }}
            collapsible
            theme="light"
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          >
            {this.generate_sidebar()}
          </Sider>
        </Space>
        <Layout>
          <Header
            style={{
              background: '#fff',
              padding: 0,
            }}
          >
            <PagesHeaderContainer
              isAuthenticated={this.props.isAuthenticated}
              userHasAuthenticated={this.props.userHasAuthenticated}
              handleLogout={this.props.handleLogout}
            />
          </Header>
          <Layout>
            <Content style={{ margin: '16px' }}>
              <div
                style={{
                  padding: 24,
                  background: '#fff',
                  minHeight: 360,
                }}
              >
                <Switch>
                  {pagesRoutes.map((prop, key) => {
                    if (prop.collapse) {
                      return null;
                    }
                    if (prop.redirect) {
                      return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                    }
                    return <AppliedRoute path={prop.path} component={prop.component} key={key} />;
                  })}
                </Switch>
              </div>
            </Content>
          </Layout>
          <Footer style={{ textAlign: 'left' }}>
            <img alt="brand" src={require('../../assets/img/brand/TickWithBorder_20px.png')} />
            <strong>
              Platinumjobseeker<sup>TM</sup>
            </strong>{' '}
            Admin Portal © 2020-2021
            <br />
            <small>Version 0.7.0</small>
            <br/>
            <Row>
              <Col xs={24} style={{ textAlign: 'right' }}>
                <small>
                Created by{' '}
                  <a href="https://deliostech.com/" target="_blank">
                  Delios Technology Inc
                  </a>
                </small>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default Pages;
