// import React from 'react';

// const JobProfileListPageComponent = () => {
//   return <React.Fragment>JOB LIST COMPONENT</React.Fragment>;
// }

// export default JobProfileListPageComponent;

import React, {
  useState,
  useEffect
} from 'react';
import {
  Card, PageHeader, Button
} from 'antd';
import {
  useLocation,
  useHistory
} from 'react-router-dom';

import JobProfileListPageComponent from '../../../../components/pages/dashboard/jobProfiles/JobProfilesListPageComponent';

const JobProfileListPage = () => {
  const location = useLocation();
  const history = useHistory();
  
  const onClickShowAll = () => {
    history.push('/applicants/job-profiles');
  }

  // const onClickNewJobs = () => {
  //   history.push('/jobs/new');
  // }

  return (

    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Jobs Profile Management'
      />
      <Card
        style={{ minHeight: 360 }}
        title='Job Profile List'
        extra={[
          <Button key="1" onClick={onClickShowAll} type="primary">Show All</Button>,
        ]}
      >
        <div style={{ paddingTop: 24 }}>
          <JobProfileListPageComponent />
        </div>
      </Card>
    </div>

  );
}

export default JobProfileListPage;
