export default {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'com.forexworldwide.crm.documents.01',
  },
  apiGateway: {
    REGION: 'us-east-1',
    // URL: "https://9s3zyajdba.execute-api.ap-southeast-2.amazonaws.com/dev"
  },
  cognito_admins: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_NkcBGmAG2',
    APP_CLIENT_ID: '6avnql4slvqdp27n671g47esbh',
    IDENTITY_POOL_ID: 'us-east-1:4c8037d5-18db-4967-9f25-fddbdad40ce1',
  },
  cognito_advertisers: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_hbd1XEphh',
    APP_CLIENT_ID: '2nm884mg20r7dpq44qfbia2hu1',
    IDENTITY_POOL_ID: 'us-east-1:a1a8fddf-c8b0-45d4-84b0-072013f6f356',
  },
  cognito_applicants: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_qNmDotQ0t',
    APP_CLIENT_ID: '6i7312jfoetsq2ccmkum82ipg2',
    IDENTITY_POOL_ID: 'us-east-1:6fd778cd-e84b-4fe6-a787-65ea5df91ff0',
  },
};
