import React, { Component } from 'react';

import {
  Card, PageHeader, Button
} from 'antd';
import ApplicantNewPageBody from './body';

class ApplicantNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handle_show_all = () => {
    this.props.history.push('/applicants/list/');
  }

  handle_new_applicants = () => {
    this.props.history.push('/applicants/new');
  }

  render() {
    // console.log(this.state);
    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Applicant Management"
        />
        <Card
          style={{ minHeight: 360 }}
          title="Create a New Applicant Record"
          extra={[
            <Button key="3" onClick={this.handle_new_applicants} type="primary">Create New</Button>,
            <Button key="2" disabled>View Record</Button>,
            <Button key="1" onClick={this.handle_show_all}>Show All</Button>,
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <ApplicantNewPageBody />
          </div>
        </Card>
      </div>

    );
  }
}

export default ApplicantNewPage;
