import { billingAdvType } from '../type';
import { getAllBillingAdvAPI, getBillingAdvAPI } from '../../services/billing_advertisers';

/* Define New action  */
const getAllBillingAdv = () => {
  return (dispatch) => {
    return getAllBillingAdvAPI()
      .then((data) => {
        if (data) {
          return dispatch({
            type: billingAdvType.GET_ALL_ADV_SUB,
            allBillingAdv: data.data,
          });
        } // TODO Error Action Calling
      })
      .catch((error) => {
        // TODO Error Action Calling
      });
  };
};

const getBillingAdv = (id) => {
  return (dispatch) => {
    return getBillingAdvAPI(id)
      .then((data) => {
        if (data) {
          return dispatch({
            type: billingAdvType.GET_ADV_SUB,
            selectedBillingAdv: data.data,
          });
        } // TODO Error Action Calling
      })
      .catch((error) => {
        // TODO Error Action Calling
      });
  };
};

export { getAllBillingAdv, getBillingAdv };
