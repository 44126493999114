
import React, { useState, useEffect } from 'react';
import withStyles from 'react-jss';
import { Row, Col, Button, Form, Input } from 'antd';

const styles = theme => ({
  form_label: { fontWeight: theme.label_font_weight },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
})

function UserSummary(props) {
  const { updateUserProfileHandler, userProfile, classes } = props;
  const [summaryInfo, setSummaryInfo] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    email: ''
  });
  useEffect(() => {
    setSummaryInfo(prev => ({
      ...prev,
      ...userProfile?.admin_profile
    }));
  }, [userProfile]);

  const handleSubmit = async event => {
    event.preventDefault();
    updateUserProfileHandler(summaryInfo);
  };
  const handleChange = event => {
    const { id, value } = event.target;
    setSummaryInfo(prev => ({
      ...prev,
      [id]: value
    }));
  };

  return (
    <React.Fragment>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Row gutter={[12, 0]}>
              <Col xs={12}>
                <Form.Item
                  label="First Name"
                  className={classes.form_label}
                >
                  <Input
                    id="first_name"
                    value={summaryInfo.first_name}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label="Middle Name(s)"
                  className={classes.form_label}
                >
                  <Input
                    id="middle_name"
                    value={summaryInfo.middle_name}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Last Name"
              className={classes.form_label}
            >
              <Input
                id="last_name"
                value={summaryInfo.last_name}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="E-mail"
              className={classes.form_label}
            >
              <Input
                id="email"
                value={summaryInfo.email}
                className={classes.disabled_input}
                disabled
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item
              label="Change Password"
              className={classes.form_label}
            >
              <Button onClick={() => { }}>
                Change Password
                </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[96, 24]} >
          <Col xs={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" onClick={handleSubmit}>
              Save Record
              </Button>
          </Col>
        </Row>
      </Form>
      {/*
      <Modal
        title="Change Password"
        visible={change_password_dialog}
        onOk={() => this.handle_change_password_ok(this.state.current_password, this.state.new_password, this.state.confirm_new_password)}
        confirmLoading={confirmLoading}
        onCancel={this.handle_change_password_cancel}
      >
        <React.Fragment>
          <p>
            Please enter your current password.
              <br />
            <br />
              You should also enter a new password (and confirm it) in the fields below.
              <br />
          </p>
          <Form layout="vertical">
            <Form.Item
              label="Current Password"
              validateStatus={this.state.current_password_state}
              help={this.state.current_password_help}
              className={classes.form_label}
            >
              <Input.Password
                id="current_password"
                placeholder="Current password"
                value={this.state.current_password}
                onChange={event => {
                  this.handleChange(event);
                }}
              />
            </Form.Item>
            <Form.Item
              label="New Password"
              validateStatus={this.state.new_password_state}
              help={this.state.new_password_help}
              className={classes.form_label}
            >
              <Input.Password
                id="new_password"
                placeholder="New password"
                value={this.state.new_password}
                onChange={event => {
                  this.handleChange(event);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              validateStatus={this.state.confirm_new_password_state}
              help={this.state.confirm_new_password_help}
              className={classes.form_label}
            >
              <Input.Password
                id="confirm_new_password"
                placeholder="Confirm new password"
                value={this.state.confirm_new_password}
                onChange={event => {
                  this.handleChange(event);
                }}
              />
            </Form.Item>
          </Form>
        </React.Fragment>
              </Modal> */}

    </React.Fragment>
  );
}

export default withStyles(styles, { injectTheme: true })(UserSummary);
