import { jobClassificationsType } from '../type';

const initialState = [];

function jobClassifications(state = initialState, action) {
  switch (action.type) {
    case jobClassificationsType.GET_JOB_CLASSIFICATIONS:
      return action.job_classifications;
    default:
      return state;
  }
}

export default jobClassifications;
