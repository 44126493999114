import React, {
  useState,
  useEffect
} from 'react';

import {
  NavLink
} from 'react-router-dom';
import { Table, Spin } from 'antd';
import { connect } from 'react-redux';

import listHOC from '../../../../../components/common/listHOC';
import { getSubscriptions } from '../../../../../redux/actions/subscription';

const BillingApplicantListPageComponent = (props) => {

  const { getSubscriptionsHandler, subscriptions } = props;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadingAPIs();
  }, []);
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (highlighter, record) => <NavLink to={`/billing/subscriptions/view/${record.id}`} >{record.id}</NavLink>,
    },
    {
      title: 'Product',
      dataIndex: 'file_by',
      render: (highlighter, record) => `${record.subscription_products.file_by} (${record.month_count} month)`,
    },
    {
      title: 'Price',
      dataIndex: 'price',
    }
  ];

  const loadingAPIs = async() => {
    setLoading(true);
    await getSubscriptionsHandler();
    setLoading(false);
  };

  return <React.Fragment>
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={subscriptions}
      />
    </Spin>
  </React.Fragment>;
};

const mapStateToProps = state => {
  return {
    subscriptions: state.subscriptions.subscriptions
  };
};


const mapDispatchToProps = ({
  getSubscriptionsHandler: getSubscriptions,
});

export default connect(mapStateToProps, mapDispatchToProps)(listHOC(BillingApplicantListPageComponent));
