
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, notification, Form, Input, Select, Typography, Skeleton } from 'antd';
import { updateAdertiser } from '../../../../../redux/actions/advertisers';
import { getCountries } from '../../../../../redux/actions/countries';
import CountrySeleteComponent from '../../../../../components/common/CountrySeleteComponent';
const { Title } = Typography;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class AdvertiserAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      street_address_line_1: '',
      street_address_line_2: '',
      street_address_suburb: '',
      street_address_state: '',
      street_address_postcode: '',
      street_address_country: '',
      data_countries_list: [],
      loadded: true
    }
  }

  async componentDidMount() {
    const advertiser = this.props.advertisers.find(item => item.id == this.props.client_id);
    this.setState({ ...advertiser });

    await this.props.getCountriesHandler();

    const data_countries_list = this.props.getCountriesHandler().then(response => {
      // console.log(response);
      // return response.countries;
      this.setState({ data_countries_list: response.countries })
      this.setState({ loadded: false })
    })
      .catch(error => {
        console.log(error);
        this.setState({ data_countries_list: [] })
      });

    // await API.get(
    //   'countries',
    //   '/list'
    // )
    //   .then(response => {
    //     // console.log(response.payload);
    //     // return response.payload;
    //     this.setState({data_countries_list: response.payload})
    //     this.setState({loadded: false})
    //   })
    //   .catch(error => {
    //     console.log(error);
    //     this.setState({data_countries_list: []})
    //   })
  }

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleSelectChange_street_address_country = (value) => {
    this.setState({ 'street_address_country': value });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const {
      street_address_line_1,
      street_address_line_2,
      street_address_suburb,
      street_address_state,
      street_address_postcode,
      street_address_country
    } = this.state;
    this.setState({ isLoading: true });
    this.props.updateAdertiserHandler(this.props.client_id, {
      street_address_line_1,
      street_address_line_2,
      street_address_suburb,
      street_address_state,
      street_address_postcode,
      street_address_country
    })
      .then(data => {
        this.setState({
          street_address_line_1: data.street_address_line_1,
          street_address_line_2: data.street_address_line_2,
          street_address_suburb: data.street_address_suburb,
          street_address_state: data.street_address_state,
          street_address_postcode: data.street_address_postcode,
          street_address_country: data.street_address_country,
        });
        this.setState({ isLoading: false });
      }).catch(erorr => {
        this.setState({ isLoading: false });
      })
  };

  render() {
    // console.log(this.state);
    // console.log(this.props);
    const { Option } = Select;

    const country_list_select_options = this.state.data_countries_list.map(d => <Option key={d.id} >{d.full_name}</Option>);

    // if (!this.state.data_countries_list || !country_list_select_options) {
    //   return null;
    // }

    return (

      <React.Fragment>
        <Skeleton
          active={this.state.loadded}
          loading={this.state.loadded}
        >
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            <Row gutter={[96, 24]}>
              <Col xs={12}>
                <Title level={4}>Street Address</Title>
                <Form.Item
                  label="Street Address (Line 1)"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    id="street_address_line_1"
                    value={this.state.street_address_line_1}
                    onChange={event => {
                      this.handleChange(event);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Street Address (Line 2)"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    id="street_address_line_2"
                    value={this.state.street_address_line_2}
                    onChange={event => {
                      this.handleChange(event);
                    }}
                  />
                </Form.Item>
                <Row gutter={[12, 0]}>
                  <Col xs={12}>
                    <Form.Item label="Suburb"
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        id="street_address_suburb"
                        value={this.state.street_address_suburb}
                        onChange={event => {
                          this.handleChange(event);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={8}>
                    <Form.Item
                      label="State"
                      style={{ marginBottom: 0 }}>
                      <Input
                        id="street_address_state"
                        value={this.state.street_address_state}
                        onChange={event => {
                          this.handleChange(event);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={4}>
                    <Form.Item label="PostCode"
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        id="street_address_postcode"
                        value={this.state.street_address_postcode}
                        onChange={event => {
                          this.handleChange(event);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label="Country">
                  <CountrySeleteComponent
                    id="street_address_country"
                    value={
                      typeof this.state.street_address_country === 'number'
                        ? this.state.street_address_country.toString()
                        : this.state.street_address_country
                    } handleChange={this.handleSelectChange_street_address_country} />
                </Form.Item>
                <br />
              </Col>
            </Row>

            <Row gutter={[96, 24]} >
              <Col xs={24} style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
                  Save Record
                </Button>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    advertisers: state.advertisers.advertisers,
  };
};

const mapDispatchToProps = ({
  updateAdertiserHandler: updateAdertiser,
  getCountriesHandler: getCountries
})

const AdvertiserAddressContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvertiserAddress);

export default AdvertiserAddressContainer;
