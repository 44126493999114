import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setLanguageSelectId } from '../../redux/actions/language';
import { DashboardOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Select, PageHeader, Button } from 'antd';
import ReactCountryFlag from 'react-country-flag';

const { Option } = Select;
function DashboardHeader(props) {
  const {
    setLanguageSelectId, history, handleLogout, language, isAuthenticated
  } = props;

  const handleLanguageSelect = (value) => {
    setLanguageSelectId(value);
  };

  const handleDashboard = async (event) => {
    history.push('/dashboard');
  };

  const handleUser = async (event) => {
    history.push('/user-profile?tab=summary');
  };

  const logout = (event) => {
    handleLogout();
    history.push('/');
  };

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title="Admin Portal"
        extra={
          isAuthenticated
            ? [
              // <Tooltip title={<FormattedMessage id="tooltip.title_switch_language" />}>
              //   <Select
              //     defaultValue={language.language_current_ui}
              //     style={{ width: 180 }}
              //     onChange={handleLanguageSelect}
              //   >
              //     <Option value="en">
              //       <ReactCountryFlag
              //         className="emojiFlag"
              //         countryCode="GB"
              //         style={{
              //           fontSize: '1.5em',
              //           lineHeight: '1.5em',
              //         }}
              //         aria-label="English Language"
              //       />{' '}
              //         English
              //     </Option>
              //     <Option value="zh">
              //       <ReactCountryFlag
              //         countryCode="CN"
              //         style={{
              //           fontSize: '1.5em',
              //           lineHeight: '1.5em',
              //         }}
              //         aria-label="Chinese Traditional Language"
              //       />{' '}
              //         繁體中文
              //     </Option>
              //   </Select>
              // </Tooltip>,
              <Tooltip title={<FormattedMessage id="tooltip.title_dashboard" />}>
                <Button key="1" type="primary" onClick={handleDashboard} icon={<DashboardOutlined />}></Button>
              </Tooltip>,
              <Tooltip title={<FormattedMessage id="tooltip.title_user_profile" />}>
                <Button key="2" type="primary" onClick={handleUser} icon={<UserOutlined />}></Button>
              </Tooltip>,
              <Tooltip title={<FormattedMessage id="tooltip.title_logout" />}>
                <Button key="3" type="primary" onClick={logout} icon={<LogoutOutlined />}></Button>
              </Tooltip>,
            ]
            : []
        }
      ></PageHeader>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguageSelectId: (language_id) => {
      dispatch(setLanguageSelectId(language_id));
    },
  };
};

const DashboardHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);

export default withRouter(DashboardHeaderContainer);
