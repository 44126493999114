import React, { useState, useEffect } from 'react';
import withStyles from 'react-jss';
import { Row, Col, Button, Form, Input, Typography, Select } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import CountrySeleteComponent from '../../../../components/common/CountrySeleteComponent';
const { Title } = Typography;
const { Option } = Select;
const styles = theme => ({
  form_label: { fontWeight: theme.label_font_weight },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
})

function UserAddress(props) {
  const { updateUserProfileHandler, userProfile, classes, countries } = props;
  const [addressInfo, setAddressInfo] = useState({});
  useEffect(() => {
    setAddressInfo(prev => ({
      ...prev,
      ...userProfile?.admin_profile
    }));
  }, [userProfile]);

  const handleSubmit = async event => {
    event.preventDefault();
    updateUserProfileHandler(addressInfo);
  };
  const handleChange = event => {
    const { id, value } = event.target;
    setAddressInfo(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const handleChangeStreetCountry = (value) => {
    setAddressInfo(prev => ({
      ...prev,
      'street_address_country': value
    }));
  };
  const handleChangePostalCountry = (value) => {
    setAddressInfo(prev => ({
      ...prev,
      'postal_address_country': value
    }));
  };
  const options = countries?.map((d, index) => <Option key={`countries-${index}`} value={d.id} >{d.full_name}</Option>);

  return (
    <React.Fragment>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Title level={4}>Street Address</Title>
            <Form.Item label="Country">
              <CountrySeleteComponent
                id="street_address_country"
                value={addressInfo.street_address_country}
                handleChange={handleChangeStreetCountry} />
            </Form.Item>
            <Form.Item
              label="Line 1"
              className={classes.form_label}
            >
              <Input
                id="street_address_line_1"
                value={addressInfo.street_address_line_1}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item
              label="Line 2"
              className={classes.form_label}
            >
              <Input
                id="street_address_line_2"
                value={addressInfo.street_address_line_2}
                onChange={handleChange}
              />
            </Form.Item>
            <Row gutter={[12, 0]}>
              <React.Fragment>
                {Boolean(addressInfo.street_address_has_region) && <Col xs={12}>
                  <Form.Item
                    label={<FormattedMessage id="data_new_client_page.region" defaultMessage="Region" />}
                    className={classes.form_label}
                  >
                    <Input
                      id="street_address_region"
                      value={addressInfo.street_address_region}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>}
              </React.Fragment>
              <React.Fragment>
                {Boolean(addressInfo.street_address_has_city) && <Col xs={12}>
                  <Form.Item
                    label={<FormattedMessage id="data_new_client_page.city" defaultMessage="City" />}
                    className={classes.form_label}
                  >
                    <Input
                      id="street_address_city"
                      value={addressInfo.street_address_city}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>}
              </React.Fragment>
              <React.Fragment>
                {Boolean(addressInfo.street_address_has_suburb) && <Col xs={12}>
                  <Form.Item
                    label={<FormattedMessage id="data_new_client_page.street_address_suburb" defaultMessage="Suburb" />}
                    className={classes.form_label}
                  >
                    <Input
                      id="street_address_suburb"
                      value={addressInfo.street_address_suburb}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>}
              </React.Fragment>
              <Col xs={8}>
                <Form.Item
                  label="State"
                >
                  <Input
                    id="street_address_state"
                    value={addressInfo.street_address_state}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={4}>
                <Form.Item label="PostCode"
                >
                  <Input
                    id="street_address_postcode"
                    value={addressInfo.street_address_postcode}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>

          </Col>
          <Col xs={12}>

            <Title level={4}>Postal Address</Title>
            <Form.Item label="Country">
              <CountrySeleteComponent
                id="postal_address_country"
                value={addressInfo.postal_address_country}
                handleChange={handleChangePostalCountry} />
            </Form.Item>
            <Form.Item
              label="Line 1"
              className={classes.form_label}
            >
              <Input
                id="postal_address_line_1"
                value={addressInfo.postal_address_line_1}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item
              label="Line 2"
              className={classes.form_label}
            >
              <Input
                id="postal_address_line_2"
                value={addressInfo.postal_address_line_2}
                onChange={handleChange}
              />
            </Form.Item>
            <Row gutter={[12, 0]}>
              <React.Fragment>
                {Boolean(addressInfo.postal_address_has_region) && <Col xs={12}>
                  <Form.Item
                    label={<FormattedMessage id="data_new_client_page.region" defaultMessage="Region" />}
                    className={classes.form_label}
                  >
                    <Input
                      id="postal_address_region"
                      value={addressInfo.postal_address_region}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>}
              </React.Fragment>
              <React.Fragment>
                {Boolean(addressInfo.postal_address_has_city) && <Col xs={12}>
                  <Form.Item
                    label={<FormattedMessage id="data_new_client_page.city" defaultMessage="City" />}
                    className={classes.form_label}
                  >
                    <Input
                      id="postal_address_city"
                      value={addressInfo.postal_address_city}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>}
              </React.Fragment>
              <React.Fragment>
                {Boolean(addressInfo.postal_address_has_suburb) && <Col xs={12}>
                  <Form.Item
                    label={<FormattedMessage id="data_new_client_page.postal_address_suburb" defaultMessage="Suburb" />}
                    className={classes.form_label}
                  >
                    <Input
                      id="postal_address_suburb"
                      value={addressInfo.postal_address_suburb}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>}
              </React.Fragment>
              <Col xs={8}>
                <Form.Item
                  label="State"
                >
                  <Input
                    id="postal_address_state"
                    value={addressInfo.postal_address_state}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={4}>
                <Form.Item label="PostCode"
                >
                  <Input
                    id="postal_address_postcode"
                    value={addressInfo.postal_address_postcode}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[96, 24]} >
          <Col xs={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" onClick={handleSubmit}>
              Save Record
              </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default withStyles(styles, { injectTheme: true })(injectIntl(UserAddress));